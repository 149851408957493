import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation} from "react-router-dom";
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function EditarTorneo(){
    const navigate = useNavigate();
    const routeParams = useParams();
    const location = useLocation();
    const idTorneo = location.state.idTorneo;

    const [infoUsuarioLocal, setInfoLocal]    = useState({}); // Variables localstorage
    const [torneo, setTorneo]                 = useState([]);
    const [partidosSinEditar, setPartidosSinEditar] = useState([]);
    const [partidosEditados, setPartidosEditados]   = useState([]);
    const [equipos, setEquipos]               = useState([]);
    const [estadios, setEstadios]             = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const urlVolver   = "/admin/crear-torneo/" + routeParams.username + "/1";

    
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerDatosDeTorneo(){
            let datos = { idTorneo: idTorneo }
            await axios.post("/obtener-torneo-individual", datos)
            .then(async function(response){
                setTorneo(response.data.torneo);
                setPartidosSinEditar(response.data.partidosSinEditar);
                setPartidosEditados(response.data.partidosEditados);
            });
        }
        ObtenerDatosDeTorneo();

        async function ObtenerEquipos(){
            await axios.post("/obtener-equipo-para-editar")
            .then(async function(response){
                setEquipos(response.data.equipos);
            });
        }
        ObtenerEquipos();

        async function ObtenerEstadios(){
            await axios.post("/obtener-estadios-para-editar")
            .then(async function(response){
                setEstadios(response.data.estadios);
            });
        }
        ObtenerEstadios();


        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


   
    
    async function funcionEditarPartido(datos){
        
        if(datos.idEquipoUno==="" || datos.idEquipoDos==="" || datos.idEstadio===""){
            navigate("/alerta", {state: {titulo:"Ingresar datos", mensaje:"Debes ingresar todos los datos para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else if(datos.idEquipoUno===datos.idEquipoDos){
            navigate("/alerta", {state: {titulo:"Mismo equipo", mensaje:"Debes escoger equipos diferentes para proceder.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else{
            await axios.post("/editar-partido", datos)
            .then(function(response){
                if(response.data.modificado===true){
                    window.location.reload();
                }
            });
        }
    }

    async function eliminarPartido(datos){
        console.log(datos);

        await axios.post("/eliminar-partido", datos)
        .then(function(response){
            if(response.data.status===200){
                window.location.reload();
                alert("Partido eliminado");
            }
        });
    }
    
    
    
    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Editar torneo</h1>
                    </div>
            

                     

                    {/* Formulario crear equipos */}
                    {(torneo)
                    ?<div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <img className="imagenPrincipal mb-5" alt="imagenTorneo" style={{ display: "block", margin: "auto"}} src={torneo.imagen}></img>
                            <h2 style={{textAlign: "center"}} >{torneo.nombre}</h2>
                            <p  style={{textAlign: "center"}}>{torneo.deporte}</p>
                        </div>
                    </div>
                    :<p>Recuperando datos</p>
                    }



                    {/* PARTIDOS SIN EDITAR */}
                    <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                        <h3 style={{textAlign: "center"}} className=" mb-3">Partidos sin editar</h3>
                        { (partidosSinEditar.length>0)
                        ? partidosSinEditar.map( (partidoActual) => (
                            <div key={partidoActual._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">
    
                                {/* idPartido  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.idPartido}  />
                                        <label htmlFor="floatingInputGrid">id Partido</label>
                                    </div>
                                </div>

                                {/* Selección equipo 1 */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`equipoUno_${partidoActual.idPartido}`} aria-label="Seleccione equipo">
                                            <option value="">Seleccionar equipo</option>
                                            {equipos.map(equipo => (
                                                <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`equipoUno_${partidoActual.idPartido}`}>Equipo 1</label>
                                    </div>
                                </div>

                                {/* Selección equipo 2 */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`equipoDos_${partidoActual.idPartido}`} aria-label="Seleccione equipo">
                                            <option value="">Seleccionar equipo</option>
                                            {equipos.map(equipo => (
                                                <option key={equipo.idEquipo} value={equipo.idEquipo}>{equipo.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`equipoDos_${partidoActual.idPartido}`}>Equipo 2</label>
                                    </div>
                                </div>

                                {/* Selección estadio */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                            <select className="form-select" id={`estadio_${partidoActual.idPartido}`} aria-label="Seleccione estadio">
                                            <option value="">Seleccionar estadio</option>
                                            {estadios.map(estadio => (
                                                <option key={estadio.idEstadio} value={estadio.idEstadio}>{estadio.nombre}</option>
                                            ))}
                                        </select>
                                        <label htmlFor={`estadio_${partidoActual.idPartido}`}>Estadio</label>
                                    </div>
                                </div>
                                {/* Selección de fecha y hora */}
                                <div className="col-sm-2">
                                    <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    className="form-control"
                                    />
                                </div>

                                {/* Botón actualizar */}
                                <div className="col-sm-2">
                                    <button
                                    className="w-100 btn btn-primary"
                                    id={partidoActual.idPartido}
                                    onClick={(event) => {
                                        const idPartido = event.target.id;
                                        const datosAdicionales = {
                                        idPartido: event.target.id,
                                        idEquipoUno: document.getElementById(`equipoUno_${idPartido}`).value,
                                        idEquipoDos: document.getElementById(`equipoDos_${idPartido}`).value,
                                        fecha: startDate,
                                        dia: startDate.getDate(),
                                        mes: startDate.getMonth() + 1,
                                        año: startDate.getFullYear(),
                                        hora: startDate.getHours(),
                                        minuto: startDate.getMinutes(),
                                        idEstadio: document.getElementById(`estadio_${idPartido}`).value,
                                        };
                                        funcionEditarPartido(datosAdicionales);
                                    }}
                                    >
                                    Actualizar Partido
                                    </button>
                                </div>
                            </div>
                        ))
                        : <p>Aún cargando</p>
                        }
                    </div>  

                    {/* PARTIDOS EDITADOS */}
                    <div className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 border" style={{maxHeight: "1000px"}} >
                        <h3 style={{textAlign: "center"}} className=" mb-3">Partidos editados</h3>   
                        {(partidosEditados.length>0)
                        ? partidosEditados.map( (partidoActual) => (
                            <div key={partidoActual._id} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                {/* idPartido  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual._doc.idPartido}  />
                                        <label htmlFor="floatingInputGrid">id Partido</label>
                                    </div>
                                </div>

                                {/* Equipo 1  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEquipoUno}  />
                                        <label htmlFor="floatingInputGrid">Equipo Uno</label>
                                    </div>
                                </div>

                                {/* Equipo 2  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEquipoDos}  />
                                        <label htmlFor="floatingInputGrid">Equipo Dos</label>
                                    </div>
                                </div>

                                {/* Estadio  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual.nombreEstadio}  />
                                        <label htmlFor="floatingInputGrid">Estadio</label>
                                    </div>
                                </div>

                                {/* Fecha  */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={partidoActual._doc.fechaHora.dia + "/" + partidoActual._doc.fechaHora.mes + "/" + partidoActual._doc.fechaHora.año + ". " + partidoActual._doc.fechaHora.hora + ":" + partidoActual._doc.fechaHora.minuto}  />
                                        <label htmlFor="floatingInputGrid">Fecha</label>
                                    </div>
                                </div>


                                {/* Botón actualizar */}
                                <div className="col-sm-2">
                                    <button
                                    className="w-100 btn btn-danger"
                                    onClick={(event) => {
                                        const datosAdicionales = {
                                        idPartido: partidoActual._doc.idPartido,
                                        idTorneo: idTorneo
                                        };
                                        eliminarPartido(datosAdicionales);
                                    }}
                                    >
                                    Eliminar partido
                                    </button>
                                </div>

                            </div>
                        ))
                        :<p>aún no hay partidos</p>
                        } 
                    </div>

                </div>
            </div>

            
        </div>
    )
}

export default EditarTorneo;