

function FooterInicioRegistro(){

    return(
        <div>
            <div className='d-flex flex-column text-center mt-5'>
                <div ><p style={{color: '#D9D9D9'}} >Copyright &copy; 2024, Cómo la vio. <b>V-1</b> </p></div>
                <div style={{margin: '-20px 0px 0px 0px'}} ><p style={{color: '#D9D9D9'}}><i className='enlaces'>Powered by</i><a style={{textDecoration: 'none', color: '#0E1465'}} rel="noreferrer" target='_blank' href="https://pretwor.com/" ><i className='enlaces'> pretwor</i></a></p></div>
                <div style={{margin: '0px 0px 0px 0px'}} ><a style={{color: '#0E1465', textDecoration: 'none'}} href="/recuperar-contrasena" >Recuperar contraseña</a></div>
                <div style={{margin: '10px 0px 0px 0px'}} ><a style={{color: '#0E1465', textDecoration: 'none'}} href="/" >volver a Home</a></div>
            </div>
        </div>
    )

}

export default FooterInicioRegistro