import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";


function CompletarProcesos(){
    const navigate    = useNavigate();
    const routeParams = useParams();
    const location    = useLocation(); 
    let {username, pagina} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]  = useState({}); // Variables localstorage
    const [videosEnsambados, setVideosEnsamblados] = useState(null);
    const [videoCargasE1, setVideoCargasE1] = useState(null);
    const [videoCargasE2, setVideoCargasE2] = useState(null);
    const [cargando, setCargando]          = useState(false);
    const [idsEnProceso, setIdsEnProceso] = useState(() => {
        const saved = localStorage.getItem('idsEnProceso');
        return saved ? JSON.parse(saved) : [];
    });
    const urlVolver   = "/camarografo/completar-procesos/" + routeParams.username;
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Buscamos ids en proceso
        const storedIds = JSON.parse(localStorage.getItem('idsEnProceso'));
        if(storedIds) {
            setIdsEnProceso(storedIds);
        }



        async function procesosIncompletos(){
            await axios.post("/completar-procesos", {
                params: { pagina: pagina }
            })
            .then(async function(response){
                if(response.status===200){  
                    setVideosEnsamblados(response.data.videoCargasEnsanblado); setVideoCargasE1(response.data.videoCargasE1); setVideoCargasE2(response.data.videoCargasE2); }
            });
        }
        procesosIncompletos();


        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    
 
    
    async function completar(datos){
        setCargando(true);
        
        await axios.post("/completar", {
            params: { datos: datos.actual }
        })
        .then(async function(response){
            setCargando(false);
            if(response.status===200){

                // Guardar los datos en el localStorage
                const updatedIds = [...idsEnProceso, response.data.idVideo];
                setIdsEnProceso(updatedIds); // Esto también actualizará localStorage a través del useEffect
                // Recargar la página
                window.location.reload();

                alert("Proceso enviado a completar.");
            }
            else{
                window.location.reload();
                alert("Error en el proceso. Volver a intentar.");
            }
        });
    }

   async function eliminarProceso(datos){
    setCargando(true);
        
        await axios.post("/eliminar-proceso", {
            params: { datos: datos.actual }
        })
        .then(async function(response){
            setCargando(false);
            if(response.status===200){

                // Recargar la página
                window.location.reload();

                alert("Proceso eliminado correctamente.");
            }
            else{
                window.location.reload();
                alert("Error en el proceso. Volver a intentar.");
            }
        });
   }

  
   

    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Equipo</h1>
                    </div>
            


 

                    {/* Equipos existentes */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="container my-5">
                            <h2 style={{textAlign: "center"}}>Completar procesos</h2>
                            <p  className="mb-5" style={{textAlign: "center"}}>En esta sección podrás completar procesos que estén pendientes por finalizar.</p>





                            <h3 className="" style={{textAlign: "center"}}> ENSAMBLADOS</h3>

                            { (videosEnsambados && videosEnsambados.length > 0)
                            ? videosEnsambados.map( (actual, key) => (
                                <div key={actual.idVideo} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* idVideo */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.idVideo}  />
                                            <label htmlFor="floatingInputGrid">id</label>
                                        </div>
                                    </div>

                                    {/* intentos */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.intentos}  />
                                            <label htmlFor="floatingInputGrid">Intento</label>
                                        </div>
                                    </div>



                                    {/* Partido */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.nombreEquipoUno + " vs " + actual.nombreEquipoDos}  />
                                            <label htmlFor="floatingInputGrid">Partido</label>
                                        </div>
                                    </div>

                                    {/* Ver video */}
                                    <div className="col-sm-2">
                                        <div className="form-floating">
                                            <a class="btn btn-primary w-100 btn-lg" target="_blank" rel="noreferrer" href={"https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/" + actual.key} role="button">Ver</a>
                                        </div>
                                    </div>

                                

                                    
                                    {/* Botón completar */}
                                    <div className="col-sm-3">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-warning btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                completar(datosAdicionales);
                                            }}
                                        >
                                            {/* Cambiar el texto del botón según el estado */}
                                            {idsEnProceso.includes(actual.idVideo) ? "Procesando" : "Completar"}
                                            {/* Completar */}
                                        </button>
                                    </div>

                                    {/* Botón eliminar proceso porque no se ve video */}
                                    <div className="col-sm-2">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-danger btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                eliminarProceso(datosAdicionales);
                                            }}
                                        >
                                            Eliminar
                                        </button>
                                    </div>


                                </div>
                            ))
                            : <p style={{textAlign: "center"}}>No hay procesos pendientes en etapa ensamblado</p>
                            }





                            <h3 className="my-5" style={{textAlign: "center"}}> ETAPA 1</h3>

                            { (videoCargasE1 && videoCargasE1.length > 0)
                            ? videoCargasE1.map( (actual, key) => (
                                <div key={actual.idVideo} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* idVideo */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.idVideo}  />
                                            <label htmlFor="floatingInputGrid">id</label>
                                        </div>
                                    </div>

                                    {/* intentos */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.intentos}  />
                                            <label htmlFor="floatingInputGrid">Intento</label>
                                        </div>
                                    </div>

            

                                    {/* Partido */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.nombreEquipoUno + " vs " + actual.nombreEquipoDos}  />
                                            <label htmlFor="floatingInputGrid">Partido</label>
                                        </div>
                                    </div>

                                    {/* Ver video */}
                                    <div className="col-sm-2">
                                        <div className="form-floating">
                                            <a class="btn btn-primary w-100 btn-lg" target="_blank" rel="noreferrer" href={actual.urlVideo} role="button">Ver</a>
                                        </div>
                                    </div>

                                   

                                    
                                    {/* Botón completar */}
                                    <div className="col-sm-3">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-warning btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                completar(datosAdicionales);
                                            }}
                                        >
                                            {/* Cambiar el texto del botón según el estado */}
                                            {/* {idsEnProceso.includes(actual.idVideo) ? "Procesando" : "Completar"} */}
                                            Completar
                                        </button>
                                    </div>

                                            
                                    {/* Botón eliminar proceso porque no se ve video */}
                                    <div className="col-sm-2">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-danger btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                eliminarProceso(datosAdicionales);
                                            }}
                                        >
                                            Eliminar
                                        </button>
                                    </div>

                                </div>
                            ))
                            : <p style={{textAlign: "center"}}>No hay procesos pendientes en etapa uno</p>
                            }



                            <h3 className="my-5" style={{textAlign: "center"}}> ETAPA 2</h3>

                            { (videoCargasE2 && videoCargasE2.length > 0)
                            ? videoCargasE2.map( (actual, key) => (
                                <div key={actual.idVideo} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* idVideo */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.idVideo}  />
                                            <label htmlFor="floatingInputGrid">id</label>
                                        </div>
                                    </div>

                                    {/* intentos */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.intentos}  />
                                            <label htmlFor="floatingInputGrid">Intento</label>
                                        </div>
                                    </div>

            

                                    {/* Partido */}
                                    <div className="col-sm-3">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.nombreEquipoUno + " vs " + actual.nombreEquipoDos}  />
                                            <label htmlFor="floatingInputGrid">Partido</label>
                                        </div>
                                    </div>

                                    {/* Ver video */}
                                    <div className="col-sm-2">
                                        <div className="form-floating">
                                            <a class="btn btn-primary w-100 btn-lg" target="_blank" rel="noreferrer" href={actual.urlVideo} role="button">Ver</a>
                                        </div>
                                    </div>

                                   

                                    

                                    
                                    {/* Botón completar */}
                                    <div className="col-sm-3">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-warning btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                completar(datosAdicionales);
                                            }}
                                        >
                                            {/* Cambiar el texto del botón según el estado */}
                                            {/* {idsEnProceso.includes(actual.idVideo) ? "Procesando" : "Completar"} */}
                                            Completar
                                        </button>
                                    </div>

                                     {/* Botón eliminar proceso porque no se ve video */}
                                     <div className="col-sm-2">
                                        <button
                                            // Deshabilitamos el botón si actual.idVideo está en idsEnProceso
                                            //disabled={idsEnProceso.includes(actual.idVideo)}
                                            className="w-100 btn btn-danger btn-lg"
                                            onClick={(event) => {
                                                const datosAdicionales = {
                                                    actual: actual
                                                };
                                                eliminarProceso(datosAdicionales);
                                            }}
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ))
                            : <p style={{textAlign: "center"}}>No hay procesos pendientes en etapa dos</p>
                            }


                            
                        </div>
                    </div>
                </div>
            </div>

            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Completando proceso, por favor espere...</p>
                </div>
            )}
        </div>
    )
}

export default CompletarProcesos;