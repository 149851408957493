import React from "react";
import {Routes, Route, Outlet } from 'react-router-dom';
// Headers
import HeaderGlobal from "./partials/headerGlobal";
import FooterGlobal from "./partials/footerGlobal";
import HeaderUsuario from "./partials/headerUsuario";
import FooterUsuarioAdmin from "./partials/footerUsuarioAdmin";
import HeaderAdmin from "./partials/headerAdmin";
import HeaderCamarografo from "./partials/headerCamarografo";
// páginas privadas
import Registro from "./paginas/registrar";
import InicioSesion from "./paginas/Iniciosesion";
import InicioSesionAdmin from "./paginas/inicioSesionAdmin";
import InicioSesionCamarografo from "./paginas/inicioSesionCamarografio";
import Alerta from "./paginas/alerta";
import AlertaConDatos from "./paginas/alertaConDatos";
// paginas GLOGAL
import Home from "./paginas/webGlobal/home";
import Cookies from "./paginas/webGlobal/cookies";
import Terminos from "./paginas/webGlobal/terminos";
import Privacidad from "./paginas/webGlobal/politicaPrivacidad";
import Contacto from "./paginas/webGlobal/contacto";
import Faq from "./paginas/webGlobal/faq";
// Usuario
import HomeUsuario from "./paginas/usuario/homeusuario";
import Micuentausuario from "./paginas/usuario/miCuentaUsuario";
import VideoIndividual from "./paginas/usuario/videoIndividual";
// Administrador
import HomeAdmin from "./paginas/administrador/homeAdministrador";
import Micuenta from "./paginas/administrador/miCuenta";
import CrearEqipo from "./paginas/administrador/crearEquipo";
import CrearTorneo from "./paginas/administrador/crearTorneo";
import CrearEstadio from "./paginas/administrador/crearEstadio";
import EditarTorneo from "./paginas/administrador/editarTorneo";
// Camarógrafo
import HomeCamarografo from "./paginas/camarografo/homeCamarografo";
import Grabar from "./paginas/camarografo/grabar";
import Grabando from "./paginas/camarografo/grabando";
import VerVideos from "./paginas/camarografo/verVideos";
import PruebaCamara from "./paginas/camarografo/pruebaCamara";
import AnadirVoz from "./paginas/camarografo/anadirVoz";
import TodosUsuarios from "./paginas/administrador/todosUsuarios";
import VerUsuario from "./paginas/administrador/verUsuario";
import RecuperarContrasena from "./paginas/recuperarContrasena";
import PartidoEncontrado from "./paginas/camarografo/partidoEncontrado";
import CompletarProcesos from "./paginas/camarografo/completarProcesos";

function Direccionamiento(){

    /* Outleet se modifica con respecto a la ruta */
    function PlantillaGlobal() {
        return (
            <div>
                <HeaderGlobal />
                    <Outlet />
                <FooterGlobal />
            </div>
        )
    }

    function PlantillaUsuario() {
        return (
            <div>
                <HeaderUsuario />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }

    function PlantillaAdministrador() {
        return (
            <div>
                <HeaderAdmin />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }

    function PlantillaCamarografo() {
        return (
            <div>
                <HeaderCamarografo />
                    <Outlet />
                <FooterUsuarioAdmin />
            </div>
        )
    }
    


    return (
        // Web Global
        <Routes>
            <Route path="/registrar" element={<Registro />} />
            <Route path="/iniciar-sesion" element={<InicioSesion />} />
            <Route path="/iniciar-sesion-administrador" element={<InicioSesionAdmin />} />
            <Route path="/iniciar-sesion-camarografo" element={<InicioSesionCamarografo />} />
            <Route path="/recuperar-contrasena" element={<RecuperarContrasena />} />
            <Route path="/alerta" element={<Alerta />} />
            <Route path="/alerta-datos" element={<AlertaConDatos />} />
            <Route  path="grabando-ios/:username/:datosPartido" element={<Grabando />}/>  
            <Route  path="prueba" element={<PruebaCamara />}/> 
            {/* Rutas Global */}
            <Route path="/" element={<PlantillaGlobal />}>
                <Route index element={<Home />}/>
                <Route  path="cookies" element={<Cookies />}/>
                <Route  path="terminos" element={<Terminos />}/>
                <Route  path="privacidad" element={<Privacidad />}/>
                <Route  path="contacto" element={<Contacto />}/>
                <Route  path="faq" element={<Faq />}/>
            </Route>
            {/* Rutas Usuario */}
            <Route path="/cliente" element={<PlantillaUsuario />}>
                <Route  path="home/:username/:pagina" element={<HomeUsuario />}/>
                <Route  path="home/video/:username/:idvideo" element={<VideoIndividual />}/>
                <Route  path="mi-cuenta/:username" element={<Micuentausuario />}/>
            </Route>
            {/* Rutas Administrador */}
            <Route path="/admin" element={<PlantillaAdministrador />}>
                <Route  path="home/:username" element={<HomeAdmin />}/> 
                <Route  path="mi-cuenta/:username" element={<Micuenta />}/>
                <Route  path="crear-equipo/:username/:pagina" element={<CrearEqipo />}/>
                <Route  path="crear-torneo/:username/:pagina" element={<CrearTorneo />}/>
                <Route  path="crear-estadio/:username/:pagina" element={<CrearEstadio />}/>
                <Route  path="editar-torneo/:username" element={<EditarTorneo />}/>
                <Route  path="usuarios/:username/:pagina" element={<TodosUsuarios />}/>
                <Route  path="ver-usuario/:username/:idUsuario" element={<VerUsuario />}/>
            </Route>
            {/* Rutas Camarógrafo */}
            <Route path="/camarografo" element={<PlantillaCamarografo />}>
                <Route  path="home/:username" element={<HomeCamarografo />}/> 
                <Route  path="grabar/:username/:pagina" element={<Grabar />}/> 
                <Route  path="ver-videos-partido/:username/:pagina" element={<VerVideos />}/> 
                <Route  path="anadir-voz/:username" element={<AnadirVoz />}/> 
                <Route  path="ver-partido-encontrado/:username" element={<PartidoEncontrado />}/> 
                <Route  path="completar-procesos/:username" element={<CompletarProcesos />}/> 
            </Route>
        </Routes>
    );   
}


export default Direccionamiento;