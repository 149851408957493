import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

 
function CrearEstadio(){
    const navigate    = useNavigate();
    const routeParams = useParams();
    const location    = useLocation(); 
    let {username, pagina} = routeParams; // capturamos para paginación

    const [siguientePagina, setSiguientePagina]     = useState(null);
    const [paginaAnterior, setPaginaAnterior]       = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const [infoUsuarioLocal, setInfoLocal]          = useState({}); // Variables localstorage
    const [estadios, setEstadios]                   = useState([]);
    const [archivo, setArchivo]                     = useState(null); // imagen subida
    const urlVolver   = "/admin/crear-estadio/" + routeParams.username + "/1";
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerEstadios(){
            await axios.post("/obtener-estadios", {
                params: { pagina: pagina }
            })
            .then(async function(response){
                if(response.status===200){ 
                    setEstadios(response.data.estadios); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)
                }
            });
        }
        ObtenerEstadios();

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    // FORMULARIO /////////////////////////////
    const[input, setInput] = useState ({
        nombre: "",
        pais: "",
        ciudad: ""
    });

    function handleChange(event){
        const {name, value} = event.target;

        // Permite letras, números y espacios
        const filteredValue = value.replace(/[^A-Za-z0-9\s]/g, '');
    
        setInput(prevInput => { return { ...prevInput, [name]: filteredValue } });
    }
    // FORMULARIO /////////////////////////////

    
    async function crearEstadio(event){
        event.preventDefault(); // evita el parpadeo predefinid
        
        
        await axios.post("/crear-estadio", input)
        .then(function(response){
            if(response.status===200){ navigate("/alerta", {state: {titulo:"Estadio creado", mensaje:"El estadio ha sido creado correctamente.", urlMensaje: urlVolver, nombreBoton:"Volver a mi cuenta"} } );}
        });
        
    }

    async function atras(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/admin/crear-estadio/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver);
        window.location.reload();
    }

    async function adelante(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/admin/crear-estadio/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver);
        window.location.reload();
    }

    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Estadio</h1>
                    </div>
            

                    {/* Formulario crear estadiocs */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <h2 style={{textAlign: "center"}} >Registrar nuevos estadios</h2>
                            <p  style={{textAlign: "center"}}>Ingresa los datos para crear un nuevo estadio.</p>

                            {/* FORMULARIO ////////////////////////////////////////////*/}
                            <form className="mt-5">

                                {/* Nombre */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.nombre} name="nombre" type="text" className="form-control" id="floatingInput" placeholder="nombre" pattern="[A-Za-z0-9\s]*"  title="Solo puede contener letras y números."/>
                                    <label htmlFor="floatingInput">Nombre de Estadio</label>
                                </div>

                                {/* País */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.pais} name="pais" type="text" className="form-control" id="floatingInput" placeholder="pais" pattern="[A-Za-z0-9\s]*"  title="Solo puede contener letras y números." />
                                    <label htmlFor="floatingInput">País</label>
                                </div>

                                {/* Ciudad */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.ciudad} name="ciudad" type="text" className="form-control" id="floatingInput" placeholder="ciudad" pattern="[A-Za-z0-9\s]*"  title="Solo puede contener letras y números." />
                                    <label htmlFor="floatingInput">Ciudad</label>
                                </div>
                                
                               


                                {/* Botón enviar */}
                                <button onClick={crearEstadio} type="submit" style={{width: "100%"}} className="btn btn-primary">Crear estadio</button>
                            </form>
                            {/* FORMULARIO ////////////////////////////////////////////*/}
                        </div>
                    </div>


                    {/* Estadios creados */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <h2 style={{ textAlign: "center" }}>Estadios creados</h2>
                            <p style={{ textAlign: "center" }}>En esta sección puedes ver todos los estadios.</p>

                            {estadios
                                ? estadios.map((estadioActual, index) => {
                                    return (
                                        // 1 fila, 2 columnas.
                                        <div className="row mb-5" key={index}>

                                            <div className="col-md-6">
                                                {/* Índice */}
                                                <div className="form-floating mb-3">
                                                    <input value={estadioActual.idEstadio} type="text" className="form-control"  readOnly />
                                                    <label >#</label>
                                                </div>

                                                {/* Nombre */}
                                                <div className="form-floating mb-3">
                                                    <input value={estadioActual.nombre} type="text" className="form-control"  readOnly />
                                                    <label >Nombre</label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                {/* País */}
                                                <div className="form-floating mb-3">
                                                    <input value={estadioActual.pais} type="text" className="form-control" readOnly />
                                                    <label >País</label>
                                                </div>

                                                {/* Ciudad */}
                                                <div className="form-floating mb-3">
                                                    <input value={estadioActual.ciudad} type="text" className="form-control"  readOnly />
                                                    <label >Ciudad</label>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    );
                                })
                                : <p>Aún no hay estadios</p>
                            }

                            {/* Botones paginación */}
                            <div className="row">
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default CrearEstadio;