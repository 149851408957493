import styles from '../../estilos/estiloCamarografo.module.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";

 

function Grabando() {
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();
  let {username, pagina} = routeParams; // capturamos para paginación

  
  const [infoUsuarioLocal, setInfoLocal] = useState({});
  const containerRef                     = useRef(null);
  const videoRef                         = useRef(null);
  const canvasRef                        = useRef(null);
  const [flujo, setFlujo]                = useState(null);
  const [grabadora, setGrabadora]        = useState(null);
  const [buffer, setBuffer]              = useState([]);
  const [cargando, setCargando]          = useState(false);
  const [zoomLevel, setZoomLevel]        = useState(1); // Nueva barra de zoom
  const [facingMode, setFacingMode]      = useState(() => localStorage.getItem('facingMode') || 'environment');
  // Verificar si hay un mimeType guardado en localStorage
  const mimeTypeGuardado = localStorage.getItem('mimeType') || 'video/mp4; codecs=avc1.42E01E,mp4a.40.2';
  // Estado inicial del mimeType
  const [mimeAndroid, setMimeAndroid] = useState(mimeTypeGuardado); // aquí guardamos lo que esté en local.
  // Dispositivos seleccionados desde página anterior
  const [opcionSeleccionadaIzquierda, setOpcionSeleccionadaIzquierda] = useState(null);
  const [opcionSeleccionadaDerecha, setOpcionSeleccionadaDerecha] = useState(null);
  // Datos de videos usados para crear archivo aws
  const [datosVideo, setDatosVideo]    = useState({
    ancho: 0,
    alto: 0,
    fps: 0,
    sampleRate: 44100,
    videoBitsPerSecond: 0, // Mbps
    audioBitsPerSecond: 128, // Kbps
    esVertical: false
  });  
  
  // UseEffect de autenticación
  useEffect(() => {
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
    if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
    else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
  }, [navigate]);

  // UseEffect de pantalla completa y bloqueada
  useEffect(() => {

    // IMPEDIR PANTALLA BLOQUADA ////////////////////////////////////////////
    let wakeLock = null;
    async function activarWakeLock() {
      try {
        if ('wakeLock' in navigator) {
          wakeLock = await navigator.wakeLock.request('screen');
          console.log('Wake Lock activado');
        } 
        else { console.log('API de Wake Lock no soportada en este navegador.'); }
      } 
      catch (err) { console.error(`Error activando Wake Lock: ${err.name}, ${err.message}`); }
    }
    function desactivarWakeLock() {
      if (wakeLock !== null) {
        wakeLock.release()
          .then(() => {
            wakeLock = null;
            console.log('Wake Lock desactivado');
          })
          .catch(err => {
            console.error(`Error desactivando Wake Lock: ${err.name}, ${err.message}`);
          });
      }
    }
    activarWakeLock();
  

    // PANTALLA COMPLETA /////////////////////////////////////////////////////////////////////
    const enterFullScreen = () => {
      const elem = containerRef.current;
      if (elem) {
        try {
          if (elem.requestFullscreen) {
            elem.requestFullscreen(); // Estándar moderno
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen(); // Firefox
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(); // Chrome, Safari (versión más antigua)
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen(); // Internet Explorer/Edge
          } else if (elem.webkitSupportsFullscreen) {
            if (elem.webkitEnterFullscreen) {
              elem.webkitEnterFullscreen(); // Safari Desktop 5.0+, Safari Mobile 4.2+
            } else if (elem.webkitEnterFullScreen) {
              elem.webkitEnterFullScreen(); // Safari Desktop 5.0+, Safari Mobile 4.2+
            }
          }
        } catch (error) {
          console.error('Error entrando en pantalla completa:', error);
        }
      }
    };
  
    if (document.readyState === "complete") { enterFullScreen(); } // Esperar a que el documento cargue y entramos en pantalla completa,
    else { window.addEventListener('load', enterFullScreen); } // sino agrega un event listener para que enterFullScreen se ejecute una vez que la página termine de cargar.

    return () => {
      window.removeEventListener('load', enterFullScreen);
      desactivarWakeLock();  // Cleanup para desactivar Wake Lock cuando el componente se desmonta
    };
  }, []);

  function draw() {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      const { videoWidth, videoHeight } = video;
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      if (video.paused || video.ended) return;

      const scale = zoomLevel;
      const dx = (videoWidth * scale - videoWidth) / 2;
      const dy = (videoHeight * scale - videoHeight) / 2;
      context.drawImage(video, -dx, -dy, videoWidth * scale, videoHeight * scale);

      requestAnimationFrame(draw);
    }
  }
   
  // UseEffect flujo de video
  useEffect(() => {

    async function iniciarFlujoDeVideo() {
      if (flujo) { flujo.getTracks().forEach(track => track.stop()); }
  
      try {
        const esPC      = location.state.esPC;
        const isIos     = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const isAndroid = /Android/i.test(navigator.userAgent);
        // Obtenemos el facingMode guardado en localStorage o usamos 'environment' por defecto
        const modoGuardado = localStorage.getItem('facingMode') || 'environment';
        setFacingMode(modoGuardado);

        // Detectar la orientación del dispositivo
        let anchoVideo, altoVideo, esVertical;

        if (window.matchMedia("(orientation: portrait)").matches) {
          esVertical = true;
          // Modo vertical
          anchoVideo = isAndroid ? 1080 : 1080;  // Android: 720px, iOS: 1080px
          altoVideo  = isAndroid ? 1920 : 1920;  // Android: 1280px, iOS: 1920px
        } else if (window.matchMedia("(orientation: landscape)").matches) {
          esVertical = false;
          // Modo horizontal
          anchoVideo = isAndroid ? 1920 : 1920;  // Android: 1280px, iOS: 1920px
          altoVideo  = isAndroid ? 1080 : 1080;  // Android: 720px, iOS: 1080px
        }

        // Configuración de video
        const configuracionVideo = {
          ...(esPC 
          ? { deviceId: location.state.camaraSeleccionada ? location.state.camaraSeleccionada.deviceId : location.state.camaraDefault} // si es pc, añadimos selección de dispositivo.
          : null),
          facingMode: modoGuardado,
          width:  1920, 
          height: 1080, 
          frameRate: isAndroid ? 60  : 60, // Cuadros por segundo
        };

        

        // Creamos flujo
        const nuevoFlujo = await navigator.mediaDevices.getUserMedia({
          video: configuracionVideo,
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: datosVideo.sampleRate // Mejor opción para video
          }
        });

        if (videoRef.current) { 
          videoRef.current.srcObject = nuevoFlujo; 
          videoRef.current.play(); 
          videoRef.current.classList.add(styles.hidden); // Oculta video ya que no es necesario mostrarlo, solo el canvas.
        } 

        setFlujo(nuevoFlujo);
        
        
        // El buffer debe ser mayor a 22 segundos, entonces mirar consola y contar que ANTES DE CORTAR EL BUFFER, de 23 segundos.
        let bufferLimit = esPC ? 12 * 1024 * 1024 // LISTO! da 26 segundos, 3 segundos extra y corte funciona bien, sin dañar video
        : isIos ? 12 * 1024 * 1024  // LISTO! da 26 segundos, con 3 segundo extra de corte
        : isAndroid ? 12 * 1024 * 1024 // LISTO! da 30 segundos aprox, porque 6 ya es muy poco y no alamcena bien
        :  12 * 1024 * 1024; // valor por defecto en caso de que ninguna condición se cumpla
        // LOS DATOS vbps NO SE PUEDEN MODIFICAR ya que si se modifican, cambian el tamaño del buffer. deben dejarse tal cual o bien, recalcular el tamaño del buffer.
        let videoBitsPerSecond = esPC ? 5 
        : isIos ? 5 
        : isAndroid ? 5
        :  3; // valor por defecto en caso de que ninguna condición se cumpla

        // Actualizamos los datos del video
        setDatosVideo((prevState) => ({
          ...prevState,       
          ancho: configuracionVideo.width,
          alto: configuracionVideo.height,     
          fps: configuracionVideo.frameRate,   
          videoBitsPerSecond:    videoBitsPerSecond,
          esVertical: esVertical
        }));
        
        
        const canvasStream    = canvasRef.current.captureStream(); //Cambiar el flujo de video a un flujo de canvas
        const combinadoStream = new MediaStream(); // Combinar el flujo de audio original con el flujo de video del canvas
        canvasStream.getVideoTracks().forEach(track => combinadoStream.addTrack(track));
        nuevoFlujo.getAudioTracks().forEach(track => combinadoStream.addTrack(track));

        //const mimeType      = isIos ? 'video/mp4; codecs=avc1.42E01E,mp4a.40.2' : 'video/mp4; codecs=avc1.42E01E,mp4a.40.2'; // anterior
        const mimeType = isIos 
        ? 'video/mp4; codecs=avc1.42E01E,mp4a.40.2'  // iOS
        : isAndroid 
          ? mimeAndroid // Android inicia en mp4 y cambia a webm si no lo soporta
          : 'video/webm; codecs=vp8,opus'; // PC (webm v8)
      

        const nuevaGrabadoraMedia = new MediaRecorder(combinadoStream, { mimeType,
          videoBitsPerSecond: videoBitsPerSecond * 1024 * 1024, // 2 Mbps para alta calidad - variamos arriba
          audioBitsPerSecond: datosVideo.audioBitsPerSecond * 1024  // 128 Kbps para audio - variamos arriba
        });



        nuevaGrabadoraMedia.ondataavailable = (event) => {
          if (event.data && event.data instanceof Blob && event.data.size > 0) {
            setBuffer(prevBuffer => {
              let newBuffer    = [...prevBuffer, event.data];
              let newTotalSize = newBuffer.reduce((acc, curr) => acc + (curr.size || 0), 0);
              console.log("tamaño: ", newTotalSize);
              
              if (newTotalSize > bufferLimit) {
                  // Si el tamaño total excede el límite, eliminamos la segunda posición si existe
                  if (newBuffer.length > 1) {
                    console.log("cortando video");
                    newBuffer.splice(1, 1); // Elimina el segundo blob
                    newTotalSize = newBuffer.reduce((acc, curr) => acc + (curr.size || 0), 0); // recaulculamos el tamaño
                  }
              }
              return newBuffer;
            });
          } 
          else { console.error('El fragmento recibido no es un Blob o está vacío:', event.data); }
        };
        // Si el codec no funciona debemos cambiarlo.
        nuevaGrabadoraMedia.onerror = (error) => { 
          console.error('Error en la grabadora de medios:', error); 

          let codigoMensaje = error.error.code;    //  0 es Video encoding failed.
          let mensaje       = error.error.message; // Video encoding failed.
          // console.log("codigoMensaje: ", codigoMensaje);
          // console.log("mensaje: ", mensaje);

          if(codigoMensaje===0){
            console.log("iniciando cambio de mime");

            const nuevoMime = 'video/webm; codecs=vp8,opus';
            localStorage.setItem('mimeType', nuevoMime); // actualizamos mime.
            window.location.reload(); // actualizamos pagina para iniciar de nuevo
          }
        };

        nuevaGrabadoraMedia.start(1000); // usamos para api mediarecorder
        setGrabadora(nuevaGrabadoraMedia);

        // Función dibujar canvas
        videoRef.current.addEventListener('play', draw);
      } 
      catch (error) {
        const urlVolver = "/camarografo/grabar/" + username + "/1"; 
        navigate("/alerta", {state: {titulo:"Error accediendo a medios", mensaje:"No podemos acceder a tu cámara o micrófono. Intenta desde otro dispositivo", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        window.location.reload();
        console.error('Error accediendo a dispositivos de medios.', error); 
      }
    }
    iniciarFlujoDeVideo();

  }, [navigate]);

  //Use effect independiente par afunción draw luego de la primera ejecutada.
  useEffect(() => {
    draw();
  }, [zoomLevel]);

  

  const handleZoomChange = (newZoomLevel) => {
    setZoomLevel(newZoomLevel); // Actualiza el estado con el nuevo nivel de zoom
  };
  
  async function volver(){
    const urlVolver = "/camarografo/grabar/" + infoUsuarioLocal.usuario.username + "/1"; 
    navigate(urlVolver);
    window.location.reload();
  }
  
  // handle de descarga




  // async function handleUpload(datos) {
  //   let opcion = datos.tipo; // gol, atajada, oportunidad o destacada.
  //   let equipo = datos.equipo; // nombre del equipo.
    
  //   setCargando(true);
  
  //   if (grabadora) {
  //     grabadora.stop(); // Detiene la grabación y dispara `onstop` usamos para api mediarecorder
      
  
  //     grabadora.onstop = () => {
  //       // Creación del enlace de descarga para el archivo grabado
  //       if (grabadora.state === 'inactive') {
  //         const blob = new Blob(buffer, { type: 'video/mp4' });
  //           const url = URL.createObjectURL(blob);
            
  //           // Crear un enlace para descargar el archivo
  //           const a = document.createElement('a');
  //           a.href = url;
  //           a.download = 'grabacion.webm';
  //           a.click();
            
  //           // Liberar el objeto URL después de la descarga
  //           URL.revokeObjectURL(url);
  //       }
  //     };
  //   }
  // }



  async function handleUpload(datos) {
    let opcion = datos.tipo; // gol, atajada, oportunidad o destacada.
    let equipo = datos.equipo; // nombre del equipo.
    
    setCargando(true);
  
    if (grabadora) {
      grabadora.stop(); // Detiene la grabación y dispara `onstop` usamos para api mediarecorder
      
  
      grabadora.onstop = async () => {
        if (buffer.length > 0) {
          
          // Identificar si es un dispositivo iOS
          const isIOS  = /iPhone|iPad|iPod/i.test(navigator.userAgent);
          //const isIOS = false;
          const esVideoMp4 = isIOS; // true si es iOS, false para otros dispositivos
          // Crear Blob en el formato correspondiente
          const blob = new Blob(buffer, { type: isIOS ? 'video/mp4' : 'video/webm' });
  
          const chunkSize = 5 * 1024 * 1024; // fragmentos de 5MB
          const totalChunks = Math.ceil(blob.size / chunkSize); // calculamos el tamaño de fragmentos a subir.
          
          console.log("chucksize: ", chunkSize);
          console.log("blob size: ", blob.size);
          console.log("total chunks: ", totalChunks);


          //1. Iniciar carga multipart y obtener uploadId
          let uploadId;
          let key;
          let idVideo;
          try {
            console.log("1. Iniciando carga multipart.");
            const initResponse = await axios.post('/iniciar-carga-multipart', { totalChunks, opcion, esVideoMp4, equipo, username }, {
              params: { datos: location.state.datos }
            });

            // Guardamos datos obtenidos del primer paso.
            uploadId = initResponse.data.uploadId;
            key      = initResponse.data.key;
            idVideo  =  initResponse.data.idVideo; // creado a partir de v4 en servidor. id único
          } 
          catch (error) {
            console.error('Error iniciando carga multipart', error);
            setCargando(false);
            return;
          }
  
          const parts = [];
  
          //2. Subir cada fragmento
          for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end   = Math.min(start + chunkSize, blob.size);
            const chunk = blob.slice(start, end);
  
            const formData = new FormData();
            
            const extension = isIOS ? 'mp4' : 'webm'; // Determinar la extensión del archivo según el dispositivo
            formData.append('videoChunk', chunk, `recording-${i + 1}.${extension}`);
            formData.append('chunkNumber', i + 1);
            formData.append('totalChunks', totalChunks);
            formData.append('opcion', opcion);
            formData.append('uploadId', uploadId);
            formData.append('key', key);
  
            try {
              console.log("2. Subida de fragmentos.");
              const response = await axios.post('/subida-video-camarografo-chunk', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { datos: location.state.datos }
              });
  
              if (response.data.status !== 200) {
                throw new Error('Error al subir el fragmento');
              }
  
              parts.push({ ETag: response.data.ETag, PartNumber: i + 1 });
            } 
            catch (error) {
              console.error(`Error uploading chunk ${i + 1}`, error);
              setCargando(false);
              return;
            }
          }
  
          //3. Enviar petición para ensamblar los fragmentos
          try {
            console.log("3. Iniciando ensamble final.");
            const response = await axios.post('/ensamblar-fragmentos', { totalChunks, uploadId, parts, key, opcion, equipo, esVideoMp4, idVideo }, {
              params: { datos: location.state.datos, datosVideo: datosVideo}
            });
  
            if (response.data.status === 200) {
              window.location.reload();
              alert('Video subido exitosamente.');
            } 
            else {
              throw new Error('Error al ensamblar los fragmentos');
            }
          } 
          catch (error) { console.error('Error ensamblando los fragmentos', error); } 
          finally { 
            setCargando(false);  
            setBuffer([]); // Vacía el buffer
            setGrabadora(null); // Reinicia la grabadora para la siguiente grabación
          }
          
        } 
        else {
          alert('No se ha grabado ningún video.');
          setCargando(false);  
          setBuffer([]); // Vacía el buffer
          setGrabadora(null); // Reinicia la grabadora para la siguiente grabación
        }
      };
    } 
    else {
      alert('Grabadora no está disponible.');
      setCargando(false);
    }
  }
  
  
  // Función para cambiar entre cámaras
  const cambiarCamara = () => {
    const nuevoModo = facingMode === 'environment' ? 'user' : 'environment';
    // Guardamos el nuevo modo en localStorage antes de recargar la página
    localStorage.setItem('facingMode', nuevoModo);
    // Actualizamos el estado antes de recargar
    setFacingMode(nuevoModo);

    window.location.reload(); // actualizamos pagina para iniciar de nuevo
  };
  
  

  return (
    <div ref={containerRef} className={styles.container}>
      {/* Captura de medios */}
      <video  ref={videoRef}  className={styles.capturaVideo} autoPlay muted playsInline ></video>
      <canvas ref={canvasRef} className={`${styles.capturaVideo}`}></canvas>
      {/* Botones */}
      <div className={styles.botonesVideo}>
        <div className={styles.columnas}>
          {/* Columna 1 */}
          <div className={styles.columnaIzquierda}>
            <p className={styles.textoNombresEquipos} >{location.state.datos.nombreEquipoUno}</p>
            {/* gol */}
            <img 
              src="/imagenes/marca/gol.png" 
              alt="Gol" 
              className={`${styles.icono} ${opcionSeleccionadaIzquierda === 'gol' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "gol", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionadaIzquierda('gol');
                handleUpload(datosAenviar);
              }} 
            />
            {/* atajada */}
            <img 
              src="/imagenes/marca/atajada.png" 
              alt="Atajada" 
              className={`${styles.icono} ${opcionSeleccionadaIzquierda === 'atajada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "atajada", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionadaIzquierda('atajada');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Oportunidad */}
            <img 
              src="/imagenes/marca/oportunidad.png" 
              alt="Oportunidad" 
              className={`${styles.icono} ${opcionSeleccionadaIzquierda === 'oportunidad' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "oportunidad", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionadaIzquierda('oportunidad');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Destacada */}
            {/* <img 
              src="/imagenes/marca/destacada.png" 
              alt="Destacada" 
              className={`${styles.icono} ${opcionSeleccionadaIzquierda === 'destacada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "destacada", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionadaIzquierda('destacada');
                handleUpload(datosAenviar);
              }} 
            /> */}
          </div>
           {/* Columna 2 */}
           <div className={styles.columnaCentral}>
            {/* zoom */}
            <div className={styles.zoomButtons}>
              {/* Botón 1x */}
              <button
                className={styles.zoomButton}
                onClick={() => handleZoomChange(1)}
                style={{ zIndex: 100 }}
              >
                1x
              </button>

              {/* Botón 2x */}
              <button
                className={styles.zoomButton}
                onClick={() => handleZoomChange(2)}
                style={{ zIndex: 100 }}
              >
                2x
              </button>
            </div>
            {/* volver y rotar */}
            <div className={styles.volverRotar}>
            {/* Volver */}
              <img 
                src="/imagenes/marca/volver.png" 
                alt="Destacada" 
                className={ styles.iconoVolver } 
                onClick={() => {
                  volver();
                
                }} 
              />
              {/* Rotar */}
              {(location.state.esPC)
              ? null
              : <img 
                src="/imagenes/marca/rotar.png" 
                alt="Destacada" 
                className={ styles.iconoRotar } 
                onClick={() => {
                  cambiarCamara();
                }} 
              />
              }
            </div>
          </div>
          {/* Columna 3 */}
          <div className={styles.columnaDerecha}>
          <p className={styles.textoNombresEquipos} >{location.state.datos.nombreEquipoDos}</p>
            {/* gol */}
            <img 
              src="/imagenes/marca/gol.png" 
              alt="Gol" 
              className={`${styles.icono} ${opcionSeleccionadaDerecha === 'gol' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "gol", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionadaDerecha('gol');
                handleUpload(datosAenviar);
              }} 
            />
            {/* atajada */}
            <img 
              src="/imagenes/marca/atajada.png" 
              alt="Atajada" 
              className={`${styles.icono} ${opcionSeleccionadaDerecha === 'atajada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "atajada", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionadaDerecha('atajada');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Oportunidad */}
            <img 
              src="/imagenes/marca/oportunidad.png" 
              alt="Oportunidad" 
              className={`${styles.icono} ${opcionSeleccionadaDerecha === 'oportunidad' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "oportunidad", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionadaDerecha('oportunidad');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Destacada */}
            {/* <img 
              src="/imagenes/marca/destacada.png" 
              alt="Destacada" 
              className={`${styles.icono} ${opcionSeleccionadaDerecha === 'destacada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "destacada", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionadaDerecha('destacada');
                handleUpload(datosAenviar);
              }} 
            /> */}
          </div>
        </div>
      </div>
      {/* zona cargando */}
      {cargando && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
          <p className={styles.loadingText}>Subiendo video, por favor espere...</p>
        </div>
      )}
    </div>
  );
    
    
}

export default Grabando;