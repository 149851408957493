
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

function VerVideos(){

    
    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, pagina} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal] = useState({}); // Variables localstorage
    const [videosPartido, setVideosPartido] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [siguientePagina, setSiguientePagina] = useState(null);
    const [paginaAnterior, setPaginaAnterior] = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);

    
    useEffect(() => {


        async function ObtenerPartido(){
            try {
                await axios.post('/ver-videos-partido', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: {  datos: location.state.datos, pagina: pagina }
                })
                .then(async function(response){
                    if(response.status===200){ 
                        setVideosPartido(response.data.videos); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)}
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        ObtenerPartido();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    
    
    
    async function eliminarVideo(datos) {
        setCargando(true);

        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;

        try {
            await axios.post('/eliminar-video', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert('video eliminado.'); 
                }
            });
        } 
        catch (error) { console.error('Error convirtiendo video', error); }


    }
    
    async function atras(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/camarografo/ver-videos-partido/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver, {state: {datos: location.state.datos } });
        window.location.reload();
    }

    async function adelante(){
        // Debemos adjuntar la información del partido que está en local.state.datos
        let urlAver = "/camarografo/ver-videos-partido/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver, {state: {datos: location.state.datos } });
        window.location.reload();
    }
    
    async function anadirVoz(datos){
        let urlAver = "/camarografo/anadir-voz/" + routeParams.username;
        
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;

        navigate(urlAver, {state: {datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo  } });
    }

    async function finalizarProceso(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/finalizar-proceso-video', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert('video finalizado.'); 
                }
            });
        }catch(err){console.log(err);}
       
    }

    async function recortarPrimerSegundo(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/recortar-primer-segundo', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert(response.data.mensaje); 
                }
            });
        }catch(err){console.log(err);}
    }

    async function cambiarEquipo(datos){
        setCargando(true);
        let urlVideo = datos.urlVideo;
        let idVideo  = datos.idVideo;


        try {
            await axios.post('/cambiar-equipo', {
                headers: {'Content-Type': 'multipart/form-data'},
                params: {  datos: location.state.datos, urlVideo: urlVideo, idVideo: idVideo }
            })
            .then(async function(response){
                setCargando(false);
                if(response.data.status===200){ 
                    window.location.reload(); alert(response.data.mensaje); 
                }
            });
        }catch(err){console.log(err);}
    }
    

    return (
        <div className=''>

            <div className='d-flex flex-row '>
                {/* BARRA LATERAL */}
                <SidebarCamarografo />
    
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                        <h1 className="h2">Ver videos de partido</h1>
                    </div>
     
                    {(location.state !== null)
                        ? <div>
                            {/* Información del partido */}
                            <div id="" className="container bg-body-tertiary p-5 mb-4 rounded-3" >
                                <div className="d-flex justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.datos.imagenEquipoUno}></img>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={location.state.datos.imagenEquipoDos}></img>
                                        </div>
                                    </div>
                                </div>
                                <h2 className=' text-center'>{location.state.datos.nombreEquipoUno + " vs " + location.state.datos.nombreEquipoDos}</h2>
                                <p  className=' text-center'>{location.state.datos.torneo}</p>
                                <p  className=' text-center'>{location.state.datos.nombreEstadio}</p>
                            </div>
                            
                            {/* El total de columnas es: largo del arreglo / 4 */}
                            
                            <div id="contenedorVideos" className="row">
                                {(videosPartido.length > 0)
                                    ? videosPartido.map((videoActual, index) => (
                                        (videoActual.urlVideo !== "NA")
                                            ? <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center">
                                                <div className="contenedor-video-grabacion">
                                                    <video
                                                        id={`video-${index}`}
                                                        className="video-js vjs-default-skin videoEnvVerVideos"
                                                        controls
                                                        preload="auto"
                                                        poster={videoActual.urlImagen}
                                                        data-setup="{ddd}"
                                                    >
                                                        <source src={`${videoActual.urlVideo}?v=${new Date().getTime()}`}  type="video/MP4" />
                                                    </video>
                                                    {/* Poner este texto abajo del video */}
                                                    <p className="text-center mt-1">{videoActual.equipo + " - " + videoActual.tipo}</p>
                                                    
                                                    {/* Cambiar equipo */}
                                                    <button 
                                                        className="w-100 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.idVideo,
                                                            urlVideo: videoActual.urlVideo 
                                                            };
                                                            cambiarEquipo(datosAdicionales);
                                                        }}
                                                    >
                                                        Cambiar equipo
                                                    </button>
                                                    {/* Recordar 1er segundo */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.idVideo,
                                                            urlVideo: videoActual.urlVideo 
                                                            };
                                                            recortarPrimerSegundo(datosAdicionales);
                                                        }}
                                                    >
                                                        Recortar 1er Segundo
                                                    </button>
                                                    {/* Botón añadir voz */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-primary"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.idVideo,
                                                            urlVideo: videoActual.urlVideo 
                                                            };
                                                            anadirVoz(datosAdicionales);
                                                        }}
                                                    >
                                                        Añadir Voz
                                                    </button>
                                                    {/* Botón eliminar video */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-danger"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.idVideo,
                                                            urlVideo: videoActual.urlVideo 
                                                            };
                                                            eliminarVideo(datosAdicionales);
                                                        }}
                                                    >
                                                        Eliminar video
                                                    </button>
                                                    {/* Marcar como terminado */}
                                                    <button 
                                                        className="w-100 mt-1 btn btn-success"
                                                        onClick={(event) => {
                                                            const datosAdicionales = {
                                                            idVideo: videoActual.idVideo,
                                                            urlVideo: videoActual.urlVideo 
                                                            };
                                                            finalizarProceso(datosAdicionales);
                                                        }}
                                                    >
                                                        Finalizar proceso
                                                    </button>
                                                </div>
                                            </div>
                                            : null
                                    ))
                                    : <p>No hay videos en edición.</p>
                                }
                            </div>

                            {/* Botones paginación */}
                            <div className="row">
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                            </div>
                        </div>
                        : <p>En proceso</p>
                    }
                </div>
            </div>


            {/* zona cargando */}
            {cargando && (
                <div className="overlay">
                <div className="loader"></div>
                <p className="loadingText">Completando proceso, por favor espere...</p>
                </div>
            )}
        </div>
    )
    
    
}

export default VerVideos;
