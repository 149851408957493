import styles from '../../estilos/estiloCamarografo.module.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";




function Grabando() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [infoUsuarioLocal, setInfoLocal] = useState({});
  const containerRef = useRef(null);
  const videoRef  = useRef(null);
  const canvasRef = useRef(null);
  const [flujo, setFlujo] = useState(null);
  const [grabadora, setGrabadora] = useState(null);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
  const [buffer, setBuffer] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // Nueva barra de zoom

  
  
  useEffect(() => {

    async function iniciarFlujoDeVideo() {
      if (flujo) { flujo.getTracks().forEach(track => track.stop()); }

      try {
        // Configuración de medios.
        const nuevoFlujo = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
            width: { ideal: 1280, max: 1280 },
            height: { ideal: 720, max: 720 },
            frameRate: { ideal: 30, max: 30 }
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100
          }
        });

        if (videoRef.current) { 
          videoRef.current.srcObject = nuevoFlujo; 
          videoRef.current.play(); 
          videoRef.current.classList.add(styles.hidden); // Oculta video ya que no es necesario mostrarlo, solo el canvas.
        } 
        setFlujo(nuevoFlujo);

        const isMobileOrMac = /iPhone|iPad|iPod|Macintosh|Mac OS X/i.test(navigator.userAgent);
        const mimeType      = isMobileOrMac ? 'video/mp4; codecs=avc1.42E01E,mp4a.40.2' : 'video/webm; codecs=vp8,opus'; // anterior
        //const mimeType      = isMobileOrMac ? 'video/webm; codecs=vp8,opus' : 'video/webm; codecs=vp8,opus'; // anterior
        const bufferLimit   = isMobileOrMac ? (15 * 1024 * 1024) : (7 * 1024 * 1024); // mopdificar el buffer si es ios o android.

        //Cambiar el flujo de video a un flujo de canvas
        const canvasStream = canvasRef.current.captureStream();
        const nuevaGrabadoraMedia = new MediaRecorder(canvasStream, { mimeType });

        nuevaGrabadoraMedia.ondataavailable = (event) => {
          if (event.data && event.data instanceof Blob && event.data.size > 0) {
              setBuffer(prevBuffer => {
                  let newBuffer = [...prevBuffer, event.data];
                  let totalSize = newBuffer.reduce((acc, curr) => acc + (curr.size || 0), 0);
                  console.log("tamaño: ", totalSize);
                  if (totalSize > bufferLimit) {
                      // Si el tamaño total excede el límite, eliminamos la segunda posición si existe
                      if (newBuffer.length > 1) {
                          newBuffer.splice(1, 1); // Elimina el segundo blob
                      }
                  }
                  return newBuffer;
              });
          } else {
              console.error('El fragmento recibido no es un Blob o está vacío:', event.data);
          }
        };
      

        nuevaGrabadoraMedia.onerror = (error) => { console.error('Error en la grabadora de medios:', error); };

        nuevaGrabadoraMedia.start(500);
        setGrabadora(nuevaGrabadoraMedia);

        // Función dibujar canvas
        function draw() {
          if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            const { videoWidth, videoHeight } = video;
            canvas.width = videoWidth;
            canvas.height = videoHeight;

            if (video.paused || video.ended) return;

            const scale = zoomLevel;
            const dx = (videoWidth * scale - videoWidth) / 2;
            const dy = (videoHeight * scale - videoHeight) / 2;
            context.drawImage(video, -dx, -dy, videoWidth * scale, videoHeight * scale);

            requestAnimationFrame(draw);
          }
        }
        videoRef.current.addEventListener('play', draw);
      } 
      catch (error) { console.error('Error accediendo a dispositivos de medios.', error); }
    }
    iniciarFlujoDeVideo();
    
    
  


    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
    if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
    else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////




    // PANTALLA COMPLETA /////////////////////////////////////////////////////////////////////
    // Iniciar pantalla completa al cargar el componente
    const enterFullScreen = () => {
      const elem = containerRef.current;
      if (elem) {
        try {
          if (elem.requestFullscreen) {
            elem.requestFullscreen(); // Estándar moderno
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen(); // Firefox
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(); // Chrome, Safari (versión más antigua)
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen(); // Internet Explorer/Edge
          } else if (elem.webkitSupportsFullscreen) {
            if (elem.webkitEnterFullscreen) {
              elem.webkitEnterFullscreen(); // Safari Desktop 5.0+, Safari Mobile 4.2+
            } else if (elem.webkitEnterFullScreen) {
              elem.webkitEnterFullScreen(); // Safari Desktop 5.0+, Safari Mobile 4.2+
            }
          }
        } catch (error) {
          console.error('Error entrando en pantalla completa:', error);
        }
      }
    };
    
    const handleInteraction = () => {
      enterFullScreen();
      window.removeEventListener('click', handleInteraction); // se eliminan los event listeners para que esta función no se ejecute múltiples veces.
      window.removeEventListener('touchstart', handleInteraction); // se eliminan los event listeners para que esta función no se ejecute múltiples veces.
    };

    // Esperar a que el documento cargue y entramos en pantalla completa, sino agrega un event listener para que enterFullScreen se ejecute una vez que la página termine de cargar.
    if (document.readyState === "complete") { enterFullScreen(); } 
    else { window.addEventListener('load', enterFullScreen); }

    // Agregar eventos para usuarios que tocan o hacen clic en la pantalla (para dispositivos móviles)
    window.addEventListener('click', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    return () => {
      window.removeEventListener('load', enterFullScreen);
      window.removeEventListener('click', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);
    };
    // PANTALLA COMPLETA /////////////////////////////////////////////////////////////////////
  }, [navigate]);

  // Use effect independiente par afunción draw luego de la primera ejecutada.
  useEffect(() => {
    function draw() {
      if (videoRef.current && canvasRef.current) {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const { videoWidth, videoHeight } = video;
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        if (video.paused || video.ended) return;

        const scale = zoomLevel;
        const dx = (videoWidth * scale - videoWidth) / 2;
        const dy = (videoHeight * scale - videoHeight) / 2;
        context.drawImage(video, -dx, -dy, videoWidth * scale, videoHeight * scale);

        requestAnimationFrame(draw);
      }
    }

    draw();
  }, [zoomLevel]);

  const handleZoomChange = (event) => { setZoomLevel(event.target.value);  };
  
  async function volver(){
    const urlVolver = "/camarografo/grabar/" + infoUsuarioLocal.usuario.username;
    navigate(urlVolver);
    window.location.reload();
  }

  async function handleUpload(datos) {
    let opcion = datos.tipo; // gol, atajada, oportunidad o destacada.
    let equipo = datos.equipo; // nombre del equipo.
    
    setCargando(true);
  
    if (!opcion) {
      alert('Por favor, selecciona una opción antes de subir.');
      setCargando(false);
      return;
    }
  
    if (grabadora) {
      grabadora.stop(); // Detiene la grabación y dispara `onstop`
  
      grabadora.onstop = async () => {
        if (buffer.length > 0) {
          
          // Identificar si es un dispositivo iOS
          const isIOS  = /iPhone|iPad|iPod|Macintosh|Mac OS X/i.test(navigator.userAgent);
          //const isIOS = false;
          const esVideoMp4 = isIOS; // true si es iOS, false para otros dispositivos
          // Crear Blob en el formato correspondiente
          const blob = new Blob(buffer, { type: isIOS ? 'video/mp4' : 'video/webm' });
  
          const chunkSize = 5 * 1024 * 1024; // fragmentos de 5MB
          const totalChunks = Math.ceil(blob.size / chunkSize); // calculamos el tamaño de fragmentos a subir.
          
          console.log("chucksize: ", chunkSize);
          console.log("blob size: ", blob.size);
          console.log("total chunks: ", totalChunks);


          //1. Iniciar carga multipart y obtener uploadId
          let uploadId;
          let key;
          try {
            console.log("1. Iniciando carga multipart.");
            const initResponse = await axios.post('/iniciar-carga-multipart', { totalChunks, opcion, esVideoMp4 }, {
              params: { datos: location.state.datos }
            });
            // Guardamos datos obtenidos del primer paso.
            uploadId = initResponse.data.uploadId;
            key      = initResponse.data.key;
          } 
          catch (error) {
            console.error('Error iniciando carga multipart', error);
            setCargando(false);
            return;
          }
  
          const parts = [];
  
          //2. Subir cada fragmento
          for (let i = 0; i < totalChunks; i++) {
            const start = i * chunkSize;
            const end   = Math.min(start + chunkSize, blob.size);
            const chunk = blob.slice(start, end);
  
            const formData = new FormData();
            formData.append('videoChunk', chunk, `recording-${i + 1}.mp4`);
            formData.append('chunkNumber', i + 1);
            formData.append('totalChunks', totalChunks);
            formData.append('opcion', opcion);
            formData.append('uploadId', uploadId);
            formData.append('key', key);
  
            try {
              console.log("2. Subida de fragmentos.");
              const response = await axios.post('/subida-video-camarografo-chunk', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                params: { datos: location.state.datos }
              });
  
              if (response.data.status !== 200) {
                throw new Error('Error al subir el fragmento');
              }
  
              parts.push({ ETag: response.data.ETag, PartNumber: i + 1 });
            } 
            catch (error) {
              console.error(`Error uploading chunk ${i + 1}`, error);
              setCargando(false);
              return;
            }
          }
  
          //3. Enviar petición para ensamblar los fragmentos
          try {
            console.log("3. Iniciando ensamble final.");
            const response = await axios.post('/ensamblar-fragmentos', { totalChunks, uploadId, parts, key, opcion, equipo, esVideoMp4 }, {
              params: { datos: location.state.datos }
            });
  
            if (response.data.status === 200) {
              window.location.reload();
              alert('Video subido exitosamente.');
            } 
            else {
              throw new Error('Error al ensamblar los fragmentos');
            }
          } 
          catch (error) { console.error('Error ensamblando los fragmentos', error); } 
          finally { setCargando(false);  }
  
        } 
        else {
          alert('No se ha grabado ningún video.');
          setCargando(false);
        }
      };
    } 
    else {
      alert('Grabadora no está disponible.');
      setCargando(false);
    }
  }
  

  //console.log("buffer: ", buffer);
  

  return (
    <div ref={containerRef} className={styles.container}>
      {/* Captura de medios */}
      <video  ref={videoRef}  className={styles.capturaVideo} autoPlay muted playsInline ></video>
      <canvas ref={canvasRef} className={`${styles.capturaVideo}`}></canvas>
      {/* Botones */}
      <div className={styles.botonesVideo}>
        <div className={styles.columnas}>
          {/* Columna 1 */}
          <div className={styles.columnaIzquierda}>
            <p style={{fontSize: '10px'}}>{location.state.datos.nombreEquipoUno}</p>
            {/* gol */}
            <img 
              src="/imagenes/marca/gol.png" 
              alt="Gol" 
              className={`${styles.icono} ${opcionSeleccionada === 'gol' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "gol", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionada('gol');
                handleUpload(datosAenviar);
              }} 
            />
            {/* atajada */}
            <img 
              src="/imagenes/marca/atajada.png" 
              alt="Atajada" 
              className={`${styles.icono} ${opcionSeleccionada === 'atajada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "atajada", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionada('atajada');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Oportunidad */}
            <img 
              src="/imagenes/marca/oportunidad.png" 
              alt="Oportunidad" 
              className={`${styles.icono} ${opcionSeleccionada === 'oportunidad' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "oportunidad", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionada('oportunidad');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Destacada */}
            <img 
              src="/imagenes/marca/destacada.png" 
              alt="Destacada" 
              className={`${styles.icono} ${opcionSeleccionada === 'destacada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "destacada", equipo: location.state.datos.nombreEquipoUno }
                setOpcionSeleccionada('destacada');
                handleUpload(datosAenviar);
              }} 
            />
          </div>
           {/* Columna 2 */}
           <div className={styles.columnaCentral}>
            <input
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoomLevel}
              onChange={handleZoomChange}
              style={{zIndex: 100, padding: "0px 0px 0px 0px" }}
            />
            <img 
              src="/imagenes/marca/volver.png" 
              alt="Destacada" 
              className={ styles.iconoVolver } 
              onClick={() => {
                volver();
               
              }} 
            />
          </div>
          {/* Columna 3 */}
          <div className={styles.columnaDerecha}>
          <p style={{fontSize: '10px'}}>{location.state.datos.nombreEquipoDos}</p>
            {/* gol */}
            <img 
              src="/imagenes/marca/gol.png" 
              alt="Gol" 
              className={`${styles.icono} ${opcionSeleccionada === 'gol' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "gol", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionada('gol');
                handleUpload(datosAenviar);
              }} 
            />
            {/* atajada */}
            <img 
              src="/imagenes/marca/atajada.png" 
              alt="Atajada" 
              className={`${styles.icono} ${opcionSeleccionada === 'atajada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "atajada", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionada('atajada');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Oportunidad */}
            <img 
              src="/imagenes/marca/oportunidad.png" 
              alt="Oportunidad" 
              className={`${styles.icono} ${opcionSeleccionada === 'oportunidad' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "oportunidad", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionada('oportunidad');
                handleUpload(datosAenviar);
              }} 
            />
            {/* Destacada */}
            <img 
              src="/imagenes/marca/destacada.png" 
              alt="Destacada" 
              className={`${styles.icono} ${opcionSeleccionada === 'destacada' ? styles.seleccionado : ''}`} 
              onClick={() => {
                const datosAenviar = { tipo: "destacada", equipo: location.state.datos.nombreEquipoDos }
                setOpcionSeleccionada('destacada');
                handleUpload(datosAenviar);
              }} 
            />
          </div>
        </div>
      </div>
      {/* zona cargando */}
      {cargando && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
          <p className={styles.loadingText}>Subiendo video, por favor espere...</p>
        </div>
      )}
    </div>
  );
    
    
}

export default Grabando;