import styles from '../estilos/inicioRegistro.module.css';
import {useLocation, useNavigate} from 'react-router-dom';

 
function AlertaConDatos() {
  const navigate    = useNavigate();
  const location = useLocation();
  const datosPagina = location.state.datosPagina;


  // Boton con datos - redirección con datos
  async function funcionBotonDos() {
    return navigate(location.state.urlBotonDos, {
    state: datosPagina
    });
  }

  return(
    <div className={styles.containerAlertaDos} >
      
      <h1 className="my-5 text-light">¡Alerta!</h1>
      <h3 className='text-light'>{location.state.titulo}</h3>
      <p className='text-light mb-5 mt-2' style={{textAlign: 'justify'}} >{location.state.mensaje}</p>
      {(location.state.mostrarBotonUno) ? <a target={location.state.urlAfueraDeWeb ? "_blank" : "_self"}  href={location.state.urlBotonUno} rel="noreferrer" className="btn btn-warning" role='button'>{location.state.nombreBotonUno}</a> : null } 
      {(location.state.mostrarBotonDos) ? <button onClick={funcionBotonDos} className="btn btn-success my-3" >{location.state.nombreBotonDos}</button> : null }
    </div>
  )
}


export default AlertaConDatos;