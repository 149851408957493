import '../../estilos/paginas.css';
import { useEffect } from 'react';

 

function Faq() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
        {/* Primer contenedor: imagen principal */}
        <div className='contenedorFAQPRINCIPAL text-light'>
            <h1>Preguntas frecuentes</h1>
            <p className='subContenedorPrincipal'>Encuentra aquí algunas preguntas frecuentes antes de iniciar</p>
        </div>


        {/* Sexto contenedor: FAQ */}
        <div className='container my-5'>
            <div className="p-sm-5 p-3 mb-4 contenedorFAQ rounded-3">
            <div className="container-fluid py-5">
                <h1 className="display-5 fw-bold text-light">FAQ</h1>
                <p className="col-md-8 fs-4 text-light mb-3">Preguntas frecuentes.</p>
                {/* Accordion */}
                <div className="accordion" id="accordionPanelsStayOpenExample">
                    {/* Pregunta 1 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            ¿Debo pagar por ver la plataforma?
                        </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            <strong>No.</strong> Puedes disfrutar de la plataforma sin ningún pago.
                        </div>
                        </div>
                    </div>
                    {/* Pregunta 2 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            ¿ Es necesario crear una cuenta para poder ver los videos?
                        </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                        <div className="accordion-body">
                            <strong>Si.</strong> Para poder ver, compartir y ganar recompensas es importante que crees una cuenta.
                        </div>
                        </div>
                    </div>
                    {/* Pregunta 3 */}
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            ¿Puedo promocionar mi empresa en los videos?
                        </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                        <div className="accordion-body">
                            <strong>Si</strong> Para esto, es importante que te contactes con nuestro equipo de soporte en la página de contacto y responderemos en breve tu solicitud.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}



export default Faq;