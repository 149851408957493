import '../../estilos/paginas.css';
import { useEffect } from 'react';

 

function Home() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
      

      {/* Primer contenedor: imagen principal */}
      <div className=''>
            <img style={{width: "100%"}} src='/imagenes/webGlobal/clvoficial.png' alt=''></img>
            {/* <p className='subContenedorPrincipal'>Captura los mejores momentos</p> */}
      </div>

      {/* Segund contenedor: iconos y textos */}
      <div className='container mt-4'>
        <div className='row'>

          {/* Icono y texto 1 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="bi bi-camera-video"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Capturamos el partido</h4>
                <p>Los mejores momentos disponibles</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 2 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="fa-solid fa-futbol"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Diferentes deportes</h4>
                <p>Encuentra tu deporte favorito</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 3 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="bi bi-film"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Clips</h4>
                <p>Repite fragmentos del partido</p>
              </div>
            </div>
          </div>

          {/* Icono y texto 4 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'>
              <i className="fa-brands fa-slideshare"></i>
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4>Comparte</h4>
                <p>Envía clips a tus amigos!</p>
              </div>
            </div>
          </div>

        </div>
      </div>





      {/* Contendor Nuevo servicio video */}
      <div className='container mt-5'>
        {/* video */}
        <div className='container text-center d-flex align-items-center justify-content-center'>
            <div className="container-fluid py-1">
                <h2 className="display-5 fw-bold">Cómo la vio</h2>
                <p className=" mb-5">Clips con los mejores momentos de tu partido favorito</p>
                
                <iframe className='claseVideoHome'
                  src="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/videos/comolavio-1.mp4"
                  title="Cómo la vio" frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media;
                  gyroscope; picture-in-picture;
                  web-share"
                  allowFullScreen>
                </iframe>
            </div>
        </div>

        <hr className="featurette-divider" />
      </div>









      {/* Tercer contendor: imágenes y textos informativos */}
      <div className="container marketing mb-5">
        {/* Texto e imagen 1 */}
        <div className="row featurette primerContMark">
          {/* Texto */}
          <div className="col-md-7 mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5" style={{color: "#223531"}}>Plataforma de clips. <span className="text-body-secondary">Una nueva forma de disfrutar los deportes.</span></h2>
            <p className="lead">Grabamos los momentos destacados de cada encuentro y te los mostramos en nuestra plataforma.</p>
            <p className="lead">Cada partido cuenta con jugadas que deseas volver a ver una y cientos de veces. Así como compartirlas con tus amigos, y nosotros lo hacemos posible.</p>
          </div>

          {/* Imagen */}
          <div className="col-md-5"><img  className='miImagenMark' src="/imagenes/webGlobal/plataforma.jpg" alt=''></img></div>
        </div>

        <hr className="featurette-divider"/>

        {/* Texto e imagen 2 */}
        <div className="row featurette">
          {/* Imagen */}
          <div className="col-sm-12 col-md-5 "><img  className='miImagenMark' src="/imagenes/webGlobal/clips.jpg" alt=''></img></div>
          {/* Texto */}
          <div className="col-sm-12 col-md-7  mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5"  style={{color: "#223531"}}>Filtra tus clips favoritos. <span className="text-body-secondary">Por equipo, partido o deporte.</span></h2>
            <p className="lead">Sabemos que es clave poder encontrar rápidamente tus clips favoritos y por esto ponemos a tu disposición un amplio filtro par tu beneficio.</p>
            <p className="lead">Sabemos lo que te gusta y por esto en las preferencias de tu cuenta podrás determinar lo que más te gustaría ver dentro de todos los clips de la plataforma.</p>
          </div>
          
        </div>
      </div>



      


     






      

    </div>
  )
}



export default Home;