import React, {useState, useEffect} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SidebarCamarografo from "../../partials/sidebarCamarografo";

function HomeCamarografo(){
    const navigate = useNavigate();
    const routeParams = useParams();
    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [partidosGrabar, setPartidosGrabar] = useState([]);
    const [torneos, setTorneos] = useState([]);
    let {username} = routeParams; // capturamos 
    useEffect(() => {

        async function ObtenerPartidosGrabar(){
            await axios.post("/obtener-partidos-grabar-home", {})
            .then(async function(response){
                setPartidosGrabar(response.data.partidosSinEditar); setTorneos(response.data.torneos);
            });
        }
        ObtenerPartidosGrabar();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);
    
    
    
    const[input, setInput] = useState ({
        busqueda: "",
        idBusqueda: "",
        torneoSeleccionado: "",
        partidoSeleccionado: "",
    });

    function handleChange(event){
        const {name, value} = event.target;
        // Permite letras, números y espacios
        const filteredValue = value.replace(/[^A-Za-z0-9\s]/g, '');
        setInput(prevInput => { return { ...prevInput, [name]: filteredValue } });
    }

    async function buscarPartido(event){
        event.preventDefault();

        if(input.busqueda===""){ alert("debes seleccionar un filtro de búsqueda. Intenta de nuevo."); }        
        else if(input.busqueda==="id" && input.idBusqueda===""){ window.location.reload(); alert("Debes añadir un id del partido. Intenta de nuevo.");}
        else if(input.busqueda==="partidoBusqueda" && input.partidoSeleccionado===""){ window.location.reload(); alert("Debes seleccionar un partido de la lista. Intenta de nuevo.");}
        else if(input.busqueda==="torneoBusqueda" && input.torneoSeleccionado===""){ window.location.reload(); alert("Debes seleccionar un torneo de la lista. Intenta de nuevo.");}
        else{

            await axios.post("/buscar-desde-camarografo", {
                params: { tipoBusqueda: input.busqueda, idPartidoBusqueda: input.idBusqueda, torneoBusqueda: input.torneoSeleccionado, partidoBusqueda: input.partidoSeleccionado }
            })
            .then(async function(response){
                if(response.data.status===400){  window.location.reload(); alert(response.data.mensaje);}
                // Opción 1 - para ver partidos
                else if(response.data.status===200 && response.data.opcion===1){
                    let url = "/camarografo/ver-partido-encontrado/" + username;
                    return navigate(url, {state: {partido: response.data.partido} } );
                }
            });
        }
    }

   


    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarCamarografo />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Home</h1>

                        <div className="d-flex align-items-center">
                            <h4 className="textoHomeUsuario mx-2">Hola {username}</h4>
                        </div>
                    </div>

                    {/* Contenedor formulario */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <h2 style={{textAlign: "center"}} >Registrar nuevos torneos</h2>
                            <p  style={{textAlign: "center"}}>Ingresa los datos para crear un nuevo torneo.</p>

                            {/* FORMULARIO ////////////////////////////////////////////*/}
                            <form className="mt-5">
                                {/* Busqueda */}
                                <div className="form-floating mb-3">
                                <select onChange={handleChange} value={input.busqueda} name="busqueda" className="form-select" id="floatingCategoria">
                                    <option value="">Buscar por</option>
                                    <option value="id">id: Escribe el id del partido</option>
                                    <option value="partidoBusqueda">Partido: selecciona el partido</option>
                                    {/* <option value="torneoBusqueda">Torneo: selecciona el torneo</option> */}
                                </select>
                                <label htmlFor="floatingCategoria">Filtro</label>
                                </div>

                                {/* id */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.idBusqueda} name="idBusqueda" type="number" className="form-control" id="floatingInput" placeholder="nombre"   title="Solo puede contener letras y números." />
                                    <label htmlFor="floatingInput">id del Partido</label>
                                </div>

                                {/* Torneo */}
                                {/* <div className="form-floating mb-3">
                                    <select onChange={handleChange} value={input.torneoSeleccionado} name="torneoSeleccionado" className="form-select" aria-label="Seleccione torneo">
                                        <option value="">Seleccionar torneo</option>
                                        {(torneos) ? torneos.map(torneo => (
                                        <option key={torneo.idTorneo} value={torneo.idTorneo}>{torneo.nombre}</option>
                                        )):null}
                                    </select>
                                    <label>Torneo</label>
                                </div> */}

                                {/* Partido */}
                                <div className="form-floating mb-3">
                                    <select onChange={handleChange} value={input.partidoSeleccionado} name="partidoSeleccionado" className="form-select" aria-label="Seleccione partido">
                                        <option value="">Seleccionar Partido</option>
                                        {(partidosGrabar) ? partidosGrabar.map(partido => (
                                        <option key={partido._doc.idPartido} value={partido._doc.idPartido}>{partido.nombrePartido}</option>
                                        )):null}
                                    </select>
                                    <label>Partido</label>
                                </div>

                                {/* Botón enviar */}
                                <button onClick={buscarPartido} type="submit" style={{width: "100%"}} className="btn btn-primary">Buscar Partido</button>
                            </form>

                            {/* FORMULARIO ////////////////////////////////////////////*/}
                        </div>
                    </div>
            
                </div>
            </div>
        </div>
    )
}

export default HomeCamarografo;