import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";


function VerUsuario(){
    const navigate    = useNavigate();
    const routeParams = useParams(); 
    let {username, idUsuario} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]          = useState({}); // Variables localstorage
    const [usuario, setUsuario]                    = useState([]);
    const urlVolver   = "/admin/usuarios/" + username + "/1";
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerUsuario(){
            await axios.post("/ver-usuario", {
                params: {idUsuario: idUsuario}
            })
            .then(async function(response){
                if(response.status===200){ 
                    setUsuario(response.data.usuario); 
                }
            });
        }
        ObtenerUsuario();

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


 
    return(
        <div className='contenedorPaginasUsuario'>
            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                        <h1 className="h2">Ver usuario</h1>
                    </div>

                    {/* Foto y nombre */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="row">
                            {/* Imagen */}
                            <div className="col-lg-6">
                                    <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={usuario.avatar}  alt="" /></div>
                            </div>

                            {/* Nombre y texto */}
                            <div className="col-lg-6">
                                <div className="container-fluid py-5">
                                    <h1 className="display-5 fw-bold">¡Hola {usuario.nombre} !</h1>
                                    <p className="col-md-8 fs-4">Esta es la zona de tu cuenta. Aquí puedes ver toda la información referente a tus datos.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row align-items-md-stretch mb-5 ">
                        {/* Info de usuario */}
                        <div className="col-md-12">

                            <div className="h-100 p-5 rounded-3 text-light" style={{backgroundColor: "black"}} >
                                <h2>Información de usuario</h2>
                                <p className="mb-5">Aquí puedes ver la información personal de tu cuenta.</p>
                                
                                {/* usuario */}
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 pt-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 style={{marginBottom: "0px"}} >Usuario</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}} className="letraTelefono">{usuario.username}</h6>
                                        </div>
                                    </div>
                                </div>

                                {/* Correo */}
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 style={{marginBottom: "0px"}}>Correo</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}} className="letraTelefono">{usuario.email}</h6>
                                        </div>
                                    </div>
                                </div>

                                {/* Nombre */}
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 style={{marginBottom: "0px"}} >Nombre</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}} className="letraTelefono">{usuario.nombre}</h6>
                                        </div>
                                    </div>
                                </div>

                                {/* Apellidos */}
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 style={{marginBottom: "0px"}} >Apellidos</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}} className="letraTelefono">{usuario.apellido}</h6>
                                        </div>
                                    </div>
                                </div>
                            
                                {/* Teléfono */}
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 >Teléfono</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}}  className="letraTelefono">{usuario.telefono}</h6>
                                        </div>
                                    </div>
                                </div>

                                {/* Identificación */}
                                <div className="row my-2">
                                    <div className="col-lg-6">
                                        <div className="d-flex  justify-content-center justify-content-lg-end text-light p-2 bg-light bg-opacity-10 border border-light border-start-0 rounded-end">
                                            <h6 style={{marginBottom: "0px"}} >Identificación</h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-center justify-content-lg-start text-light p-2">
                                            <h6 style={{marginBottom: "0px"}} className="letraTelefono">{usuario.identificacion}</h6>
                                        </div>
                                    </div>
                                </div> 
                                
                               
                            </div>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    )
}

export default VerUsuario;