import React, {useState, useEffect} from "react";
import SidebarAdmin from "../../partials/sidebarAdmin";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";


function CrearEqipo(){
    const navigate    = useNavigate();
    const routeParams = useParams();
    const location    = useLocation(); 
    let {username, pagina} = routeParams; // capturamos para paginación

    const [siguientePagina, setSiguientePagina]     = useState(null);
    const [paginaAnterior, setPaginaAnterior]       = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const [infoUsuarioLocal, setInfoLocal]          = useState({}); // Variables localstorage
    const [equipos, setEquipos]                     = useState([]);
    const [archivo, setArchivo]                     = useState(null); // imagen subida
    const urlVolver   = "/admin/crear-equipo/" + routeParams.username + "/1";
    
    useEffect(() => {
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-administrador", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////


        async function ObtenerEquipos(){
            await axios.post("/obtener-equipos", {
                params: { pagina: pagina }
            })
            .then(async function(response){
                if(response.status===200){ 
                    setEquipos(response.data.equipos); setSiguientePagina(response.data.siguientePagina); setPaginaAnterior(response.data.paginaAnterior); setCantidadDePaginas(response.data.cantidadDePaginas)
                }
            });
        }
        ObtenerEquipos();

        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
        return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [navigate]);


    // FORMULARIO /////////////////////////////
    function controlCambioImagen(event){
        //console.log(event);
        const archivoSubido = event.target.files[0]; //la imagen está en la posición 0 del arreglo files
        // Verificamos si es JPG y no JPEG
        if(archivoSubido){
            const fileName = archivoSubido.name.toLowerCase();
            if (fileName.endsWith('.jpg') && !fileName.endsWith('.jpeg')){ setArchivo(archivoSubido); } // guardamos archivo en ele stado de archivo solo si termina en jpg.
            else{ setArchivo(null); return navigate("/alerta", {state: {titulo:"Formato incorrecto", mensaje:"Por favor sube un archivo con formato jpg.", urlMensaje: urlVolver, nombreBoton:"Volver"} });  } // Limpiar el archivo si no cumple con los requisitos y retornar
        }
        else{

        }
    }

    const[input, setInput] = useState ({
        deporte: "",
        nombre: "",
    });

    function handleChange(event){
        const {name, value} = event.target;
        // Permite letras, números y espacios
        const filteredValue = value.replace(/[^A-Za-z0-9\s]/g, '');

        setInput(prevInput => { return { ...prevInput, [name]: filteredValue } });
    }
    // FORMULARIO /////////////////////////////
 
    
    async function crearEquipo(event){
        event.preventDefault(); // evita el parpadeo predefinid
        // Si no sube un archivo se crea con avatar
        if(!archivo){ 
            await axios.post("/crear-equipo-sin-imagen", {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  datos: input }
            })
            .then(function(response){
                if(response.status===200){ navigate("/alerta", {state: {titulo:"Equipo creado", mensaje:"El equipo ha sido creado correctamente.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );}
            });
        }
        // si sube imagen, se sube imagen
        else{
            const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
            formData.append('file', archivo); // guardamos imagen en 'file'
            await axios.post("/crear-equipo", formData, {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  datos: input }
            })
            .then(function(response){
                if(response.status===200){ navigate("/alerta", {state: {titulo:"Equipo creado", mensaje:"El equipo ha sido creado correctamente.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );}
            });
        }
    }

    async function cambiarImagen(datos){
        
        if(!archivo){  navigate("/alerta", {state: {titulo:"Falta logo", mensaje:"Debes adjuntar un logo.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );  }

        else{
            const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
            formData.append('file', archivo); // guardamos imagen en 'file'

            await axios.post("/cambiar-avatar-equipo", formData, {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  idEquipo:  datos.idEquipo, deporte: datos.deporte, nombre: datos.nombre }
            })
            .then(function(response){
                if(response.data.status ===200){
                    navigate("/alerta", {state: {titulo:"Logo cambiado", mensaje:"Has cambiado exitosamente el logo.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
                }
            });
        }
    }

    async function atras(){
        let urlAver = "/admin/crear-equipo/" + routeParams.username + "/" + paginaAnterior;
        navigate(urlAver);
        window.location.reload();
    }

    async function adelante(){
        let urlAver = "/admin/crear-equipo/" + routeParams.username + "/" + siguientePagina;
        navigate(urlAver);
        window.location.reload();
    }

    return(
        <div className='contenedorPaginasUsuario'>
            


            <div className='contenedorSidebarYcontenido d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarAdmin />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">Equipo</h1>
                    </div>
            

                     

                    {/* Formulario crear equipos */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">                   
                        <div className="container">
                            <h2 style={{textAlign: "center"}} >Registrar nuevos equipos</h2>
                            <p  style={{textAlign: "center"}}>Ingresa los datos para crear un nuevo equipo.</p>

                            {/* FORMULARIO ////////////////////////////////////////////*/}
                            <form className="mt-5">

                                {/* Deporte */}
                                <div className="form-floating mb-3">
                                    <select onChange={handleChange} value={input.deporte} name="deporte" className="form-select" id="floatingCategoria">
                                        <option value="">Selecciona un deporte</option>
                                        <option value="futbol">Fútbol</option>
                                        <option value="basquetball">Basquetball</option>
                                        <option value="rugby">Rugby</option>
                                    </select>
                                    <label htmlFor="floatingCategoria">Deporte</label>
                                </div>

                                {/* Nombre */}
                                <div className="form-floating mb-3">
                                    <input onChange={handleChange} value={input.nombre} name="nombre" type="text" className="form-control" id="floatingInput" placeholder="nombre" pattern="[A-Za-z0-9\s]*"  title="Solo puede contener letras y números." />
                                    <label htmlFor="floatingInput">Nombre</label>
                                </div>
                                
                                {/* IMAGEN */}
                                <div className="form-floating mb-3">
                                    <input onChange={controlCambioImagen} className="form-control" accept=".jpg" type="file" id="formFile" /> 
                                    <label htmlFor="floatingInput">Logo</label>
                                </div>


                                {/* Botón enviar */}
                                <button onClick={crearEquipo} type="submit" style={{width: "100%"}} className="btn btn-primary">Crear equipo</button>
                            </form>
                            {/* FORMULARIO ////////////////////////////////////////////*/}
                        </div>
                    </div>




                    {/* Equipos existentes */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                        <div className="container my-5">
                            <h3 style={{textAlign: "center"}}>Equipos creados</h3>
                            <p  style={{textAlign: "center"}}>En esta sección puedes ver todos los equipos.</p>


                            { (equipos)
                            ? equipos.map( (equipoActual, key) => (
                                <div key={equipoActual.idEquipo} className="row gx-3 gy-2 align-items-center mb-1 py-3">

                                    {/* id */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={equipoActual.idEquipo}  />
                                            <label htmlFor="floatingInputGrid">id</label>
                                        </div>
                                    </div>

                                    {/* Avatar */}
                                    <div className="col-sm-1">
                                        <div className="form-floating">
                                            <img src={equipoActual.imagen} alt="avatar" style={{width: "80px", borderRadius: "20%"}} />
                                        </div>
                                    </div>

                                    {/* Deporte */}
                                    <div className="col-sm-2">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={equipoActual.deporte}  />
                                            <label htmlFor="floatingInputGrid">Deporte</label>
                                        </div>
                                    </div>

                                    {/* Nombre */}
                                    <div className="col-sm-2">
                                        <div className="form-floating">
                                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={equipoActual.nombre}  />
                                            <label htmlFor="floatingInputGrid">Nombre</label>
                                        </div>
                                    </div>

                                    {/* Imagen */}
                                    <div className="col-sm-4">
                                        <div className="form-floating">
                                            <input onChange={controlCambioImagen} className="form-control" accept=".jpg" type="file" id="formFile" /> 
                                            <label htmlFor="floatingInput">Logo</label>
                                        </div>
                                    </div>

                                    

                                    
                                    {/* Botón cambio de imagen */}
                                    <div className="col-sm-2">
                                        <button
                                        className="w-100 btn btn-warning"
                                        onClick={(event) => {
                                            const datosAdicionales = {
                                            idEquipo: equipoActual.idEquipo,
                                            deporte: equipoActual.deporte,
                                            nombre: equipoActual.nombre
                                            };
                                            cambiarImagen(datosAdicionales);
                                        }}
                                        >
                                        Cambiar Imagen
                                        </button>
                                    </div>
                                </div>
                            ))
                            : <p>Aún cargando</p>
                            }


                            {/* Botones paginación */}
                            <div className="row mt-5">
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
                                <div className="col-6"> <button className={   "w-100 my-3 btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CrearEqipo;