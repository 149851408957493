import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function SeleccionarDispositivo(props) {

    const navigate = useNavigate();
    const { camaras, microfonos, onSeleccionarDispositivo } = props; // contiene los datos recibdos
    const [camaraSeleccionada, setCamaraSeleccionada] = useState(null);
    const [microfonoSeleccionado, setMicrofonoSeleccionado] = useState(null);

    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    

    function handleCamara(event){
        const selectedIndex  = event.target.value;
        const selectedCamara = camaras[selectedIndex];
        setCamaraSeleccionada({
            index: selectedIndex,
            label: selectedCamara.label,
            deviceId: selectedCamara.deviceId,
        });
    }
    
    function handleMicrofono(event){
        const selectedIndex     = event.target.value;
        const selectedMicrofono = microfonos[selectedIndex];
        setMicrofonoSeleccionado({
            index: selectedIndex,
            label: selectedMicrofono.label,
            deviceId: selectedMicrofono.deviceId,
        });
    }
    
    // aquí enviar datos
    function enviarDatos() {
        onSeleccionarDispositivo({
            camaraSeleccionada,
            microfonoSeleccionado,
        });
        handleClose();
    }

    return(
        <div>
            <Button  variant="outline-primary" onClick={handleShow}>
                Dispositivos
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Seleccionar dispositivos</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Selecciona una cámara y un micrófono para continuar.</p>
                    
                    {/* Seleccionar cámara */}
                    <select className="form-select" aria-label="Default select example" onChange={handleCamara}>
                        <option defaultValue={""}>Seleccionar cámara</option>
                        {(camaras.length>1)
                            ?  camaras.map( (camaraActual, index) =>{
                                return(
                                    <option key={index} value={index}>{camaraActual.label}</option>
                                )})
                            :   <p>Buscando</p>
                            }
                    </select>

                    {/* Seleccionar micrófono */}
                    <select className="form-select my-3" aria-label="Default select example" onChange={handleMicrofono} >
                        <option defaultValue={""} >Seleccionar micrófono</option>
                        {(microfonos.length>1)
                            ?  microfonos.map( (microfonoActual, index) =>{
                                return(
                                    <option key={index} value={index}>{microfonoActual.label}</option>
                                )})
                            :   <p>Buscando</p>
                            }
                    </select>

                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                <Button className="w-100" variant="secondary" onClick={enviarDatos}>
                        Confirmar selección
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SeleccionarDispositivo;