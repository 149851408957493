import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function CambiarFoto(props) {

    const navigate = useNavigate();

    const { urlVolver, usuario } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [archivo, setArchivo] = useState(null); // imagen subida
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    
    function controlCambioImagen(event){
        //console.log(event);
        const archivoSubido = event.target.files[0]; //la imagen está en la posición 0 del arreglo files
        // Verificamos si es JPG y no JPEG
        if(archivoSubido){
            const fileName = archivoSubido.name.toLowerCase();
            if (fileName.endsWith('.jpg') && !fileName.endsWith('.jpeg')){ setArchivo(archivoSubido); } // guardamos archivo en ele stado de archivo solo si termina en jpg.
            else{ setArchivo(null); return navigate("/alerta", {state: {titulo:"Formato incorrecto", mensaje:"Por favor sube un archivo con formato jpg.", urlMensaje: urlVolver, nombreBoton:"Volver"} });  } // Limpiar el archivo si no cumple con los requisitos y retornar
        }
    }

    async function controlSubida(event){
        event.preventDefault(); // evita el parpadeo predefinido
        // Si no sube un archivo
        if(!archivo){ 
            setShow(false); //cerramos el modal para evitar errores
            return navigate("/alerta", {state: {titulo:"No has subido ningún archivo", mensaje:"No has subido ningún archivo, por favor intenta de nuevo.", urlMensaje: urlVolver, nombreBoton:"Volver"} });  
        }
        // Si si sube el archivo
        else{
            const formData = new FormData(); // Creamos formData para pasar la imagen sin problema.
            formData.append('file', archivo); // guardamos imagen en 'file'
            formData.append('usuario', usuario); // guardamos el usuario para buscar en db

            // Enviamos a subir la imagen
            await axios.post("/subida", formData, {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  usuario: usuario }
            })
            .then(async function(response){
                console.log(response);
                if(response.status===200){return navigate("/alerta", {state: {titulo:"Avatar modificado", mensaje:"Su avatar ha sido modificado correctamente. Por favor cierre y vuelva a iniciar sesión para visualizar.", urlMensaje: urlVolver, nombreBoton:"Cerrar Sesión"} });  }
                else{ return navigate("/alerta", {state: {titulo:"Error", mensaje:"Hay un error al actualizar. Por favor volver a intenar.", urlMensaje: urlVolver, nombreBoton:"Volver"} }); }
            });
        }
    }


    

    return(
        <div>
            <Button  variant="outline-primary" onClick={handleShow}>
                Cambiar foto
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar foto</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Agrega tu foto  tipo .JPG y preferiblemente con dimensiones: 500px x 500px.</p>
                    <form>
                        <div className="mb-3"> <input className="form-control" accept=".jpg" type="file" id="formFile" onChange={controlCambioImagen} /> </div>
                        <button type="submit" className="btn btn-primary w-100" onClick={controlSubida} >Cambiar foto</button>
                    </form>
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CambiarFoto;