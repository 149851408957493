import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import axios from "axios";

function VideoIndividual() {

    const navigate    = useNavigate();
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, idvideo} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]= useState({}); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videoMostrar, setVideoMostrar] = useState(null);
    const [cargando, setCargando] = useState(false);
    
  
    
    
    useEffect(() => {

        async function obtenerVideoIndividual(){
            try {
                await axios.post('/obtener-video-individual', {
                    headers: {'Content-Type': 'multipart/form-data'},
                    params: { idVideo: idvideo  }
                })
                .then(async function(response){
                    console.log(response);
                    if(response.status===200){ setVideoMostrar(response.data.video); }
                });
            } 
            catch (error) { console.error('Error uploading video', error); }
        }
        obtenerVideoIndividual();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    


    return(
        <div className=''>
            <div className='d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />


                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-dark">
                        <h1 className="h2">{ (videoMostrar)?  videoMostrar.video.equipo + " - " + videoMostrar.video.tipo : "" }</h1>

                        {/* Avatar y saludo */}
                        <div className="d-flex align-items-center">
                            <h4 className="textoHomeUsuario mx-3">Hola {username}</h4>
                            <img className="imgHomeUsuario" src={avatar} alt="" />
                        </div>
                    </div>


                    {/* Videos */}
                    <div className="container my-5">
                        <div id="contenedorVideos" className="row">
                            {(videoMostrar) ? (
                                <div className="contenedor-video-grabacion position-relative">
                                    {/* Envolver el video en un Link para redirigir */}
                                    <video
                                    id={videoMostrar.video.idVideo}
                                    className="video-js vjs-default-skin videoIndividual"
                                    preload="auto"
                                    controls
                                    poster={videoMostrar.video.urlImagen}
                                    data-setup="{ddd}"
                                    style={{ width: '100%' }}
                                    >
                                    <source
                                        src={videoMostrar.video.urlVideo}
                                        type="video/MP4"
                                    />
                                    </video>


                                    {/* Descripción del video */}
                                    <div className="d-flex justify-content-center align-items-center my-2">
                                        <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoMostrar.video.equipo}</strong></p>
                                        <p className="textosDescripcionUno text-center me-2 mb-0">{videoMostrar.video.tipo}</p>
                                        
                                        <div className="d-flex align-items-center me-2">
                                            <i className="textosDescripcionUno bi bi-heart me-1"></i>
                                            <p className="textosDescripcionUno text-center mb-0">{videoMostrar.video.meGusta.length}</p>
                                        </div>
                                        
                                        <div className="d-flex align-items-center me-2">
                                            <i className="textosDescripcionUno bi bi-play me-1"></i>
                                            <p className="textosDescripcionUno text-center mb-0">{videoMostrar.video.vistas}</p>
                                        </div>
                                    </div>
                                    {/* Descripción dos del video */}
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.nombreEquipoUno + " vs " + videoMostrar.nombreEquipoDos}</p>
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.torneo}</p>
                                    <p className="textosDescripcionDos text-center me-2 mb-0">{videoMostrar.estadio}</p>
                                </div>
                            ) : (
                            <p>Cargando</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default VideoIndividual;