import styles from '../estilos/inicioRegistro.module.css';
import React, {useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FooterInicioRegistro from '../partials/footerInicioRegistro';

 

function RecuperarContrasena() {

    const navigate = useNavigate();

    const [infoUsuarioLocal, setInfoLocal]= useState({
        usuario: "",
        autenticado: false
    });

    const[input, setInput] = useState ({
        usuario: "",
        email: "",
        contraseña: ""
    });
    
    function handleChange(event){
        const {name, value} = event.target;
        // guardar el valor previo.
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        });
    }

    function handleClick(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        // crear objeto para pasar a servidor
        const nUsuario = {
            username: input.usuario,
            email: input.email,
            password: input.contraseña
        }

        // pasar datos a servidor o bd.
        axios.post("/recuperar-contrasena", nUsuario )
        .then(function (response) {

            if(response.data.status===200){
                navigate("/alerta", {state: {titulo:"Contraseña modificada", mensaje:"El la contraseña ha sido cambiada correctamente. Por favor vuelva a iniciar sesión.", urlMensaje:"/", nombreBoton:"Volver"}} )
            }
            else{
                navigate("/alerta", {state: {titulo:"Error", mensaje: response.data.mensaje, urlMensaje:"/", nombreBoton:"Volver"}} )
            }
        });
    }


    return(
    <div className={styles.container}>
        <main className={styles['form-signin']}>
            <form>
            {/* Imagen y título */}
            <div className='d-flex justify-content-center'>
                <img className="my-5" src="/imagenes/marca/clvcazul.png" alt="" height="200" />
            </div>
            <h3 className="h3 mb-3 fw-normal text-light text-center">Recuperar contraseña</h3>

            {/* usuario */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.usuario} name="usuario" type="text" className="form-control"  placeholder="miusuario123" />
                <label htmlFor="floatingInput">Usuario</label>
            </div>


            {/* email */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.email} name="email" type="text" className="form-control"  placeholder="micorreo@gmail.com" />
                <label htmlFor="floatingInput">Email</label>
            </div>


            {/* Contraseña  */}
            <div className="form-floating">
                <input onChange={handleChange} value={input.contraseña} name="contraseña" type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                <label htmlFor="floatingPassword">Nueva contraseña</label>
            </div>

            {/* Botón enviar */}
            <button onClick={handleClick} className="btn btn-primary w-100 py-2" type="submit">Recuperar contraseña</button>


            {/* footer */}
            <FooterInicioRegistro />                
            </form>
        </main>
    </div>
    )
}



export default RecuperarContrasena;