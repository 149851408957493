import styles from '../estilos/inicioRegistro.module.css';
import {useLocation} from 'react-router-dom';

 
function Alerta() {
  const location = useLocation();

  return(
    <div className={styles.containerAlerta} >
      <h1 className="my-5">¡Alerta!</h1>
      <h3>{location.state.titulo}</h3>
      <p>{location.state.mensaje}</p>
      <a className="btn btn-primary" href={location.state.urlMensaje} role="button">{location.state.nombreBoton}</a>
    </div>
  )
}


export default Alerta;