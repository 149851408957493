import styles from '../../estilos/estiloCamarografo.module.css';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import SeleccionarDispositivo from "./seleccionarDispositivo";
import SidebarCamarografo from '../../partials/sidebarCamarografo';
 

function AnadirVoz() {
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();
  let {username, pagina} = routeParams; // capturamos para paginación
  
  const [infoUsuarioLocal, setInfoLocal] = useState({});
  const [camaras, setCamaras] = useState([]);
  const [microfonos, setMicrofonos] = useState([]);
  const [microfonoSeleccionado, setMicrofonoSeleccionado] = useState(null);
  const [camaraDefault, setCamaraDefault] = useState(null);
  const [microfonoDefault, setMicrofonoDefault] = useState(null);
  const [grabando, setGrabando] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [archivos, setArchivos] = useState(null);
 

  useEffect(() => {
  
    async function obtenerDispositivos(){
      // Obtener los dispositivos de entrada de video y audio disponibles
      const dispositivos = await navigator.mediaDevices.enumerateDevices();
      const camaras = dispositivos.filter(dispositivo => dispositivo.kind === 'videoinput');
      const microfonos = dispositivos.filter(dispositivo => dispositivo.kind === 'audioinput');
      setCamaraDefault(camaras[0].deviceId);
      setMicrofonoDefault(microfonos[0].deviceId);
      setCamaras( camaras);
      setMicrofonos(microfonos);
    }
    obtenerDispositivos();

    async function obtenerAudiosDeVideo(){
      if(location){
        axios.post("/obtener-audios-de-video", {
          params: {urlVideo: location.state.urlVideo}
        })
        .then(function(response){
          if(response.data.existe===true){
            setArchivos(response.data.archivos);
          }
        });
      }
    }
    obtenerAudiosDeVideo();




    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
    if(usuarioEncontrado){setInfoLocal(usuarioEncontrado);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
    else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion-camarografo", nombreBoton:"Volver a iniciar sesión"} } );}
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////

  }, [navigate]);

 
  function handleSeleccionarDispositivo(datos){
    // guardamos los dispositivos seleccionados
    setMicrofonoSeleccionado(datos.microfonoSeleccionado);
  }

  async function iniciarGrabacionVoz (){
    try {

      //Obtener los dispositivos de entrada de video y audio seleccionados con anterioridad
      const dispositivoAudio = (microfonoSeleccionado) ? microfonoSeleccionado.deviceId : microfonoDefault; 
      
      // Configurar dispositivos
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          deviceId: dispositivoAudio,
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100,
          channelCount: 2, // Estéreo en lugar de mono
          autoGainControl: false, // Desactivar control automático de ganancia si se quiere mayor control manual
        }
      });

      mediaRecorderRef.current = new MediaRecorder(stream, {audioBitsPerSecond: 128000} );

      // Almacenamos blob
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      // Función detener grabación y subir
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        audioChunksRef.current = [];

        // Crear un objeto FormData para enviar el archivo
        const formData = new FormData();
        formData.append("audioFile", audioBlob, "voz.wav");

        // Enviar el Blob al servidor usando axios
        axios.post("/subir-audio", formData, {
          params:  { urlVideo: location.state.urlVideo}
        })
        .then(response => { 
          if(response.data.status===200){
            setArchivos(null); // reiniciamos archivos
            window.location.reload();
            alert("Grabación realizada correctamente.");
          }
        })
        .catch(error => { alert("Error al grabar."); });
      };

      mediaRecorderRef.current.start();
      setGrabando(true);
      videoRef.current.play();  // Inicia el video cuando comienza la grabación de voz
    } 
    catch (error) { console.error("Error al acceder al micrófono: ", error); }
  };

  async function detenerGrabacionVoz(){
    if (mediaRecorderRef.current && grabando) {
      mediaRecorderRef.current.stop();
      setGrabando(false);
    }
  };
  
  async function reproducirTodo(){
    if (videoRef.current && audioRef.current) {
      videoRef.current.play();
      audioRef.current.play();
    }
  };
 
  async function unirTodo() {
    
    try{
      // Enviar el Blob al servidor usando axios
      axios.post("/unir-todo", {
        params:  { urlVideo: location.state.urlVideo, audio: archivos, idVideo: location.state.idVideo }
      })
      .then(response => { 
        // Redireccionar a ver Videos:
        const urlVolver   = "/camarografo/grabar/" + routeParams.username + "/1";
        navigate("/alerta", {state: {titulo:"Voz mezclada", mensaje:"Mezcla ejecutada correctamente. El video ya está disponible para los usuarios.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
      })
      .catch(error => { alert("Error al unir."); });
    }
    catch (error) { alert("Error al unir todo."); }

  }

  // Actualizar url cuando se vuelve a grabar, por el caché.
  let urlConParametro = "";
  if(archivos){urlConParametro = `${archivos}?v=${new Date().getTime()}`;}


  return (
    <div className=''>
      <div className='d-flex flex-row '>
          {/* BARRA LATERAL */}
          <SidebarCamarografo />

          {/* Columna 2 */}
          <div className="flex-grow-1 container-fluid">
            {/* Contenedor título  */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom border-dark ">
                <h1 className="h2">Añadir Voz</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                  {/* Seleccionar dispositivo */}
                  {(camaras.length>1)
                  ?<SeleccionarDispositivo camaras={camaras} microfonos={microfonos} onSeleccionarDispositivo={handleSeleccionarDispositivo} />
                  :null
                  }
                  </div>
              </div>
            </div>
            
            {/* Contenedor grabación */}
            {(location.state !== null)
            ? <div>
                {/* Información del partido */}
                <div id="" className="p-5 mb-4 rounded-3" style={{ backgroundColor: "black" }}>
                  
                  <h2 className='text-white text-center'>{location.state.datos.nombreEquipoUno + " vs " + location.state.datos.nombreEquipoDos}</h2>
                  <p className='text-white text-center'>{location.state.datos.torneo}</p>
                  <p className='text-white text-center'>{location.state.datos.estadio}</p>

                  <div className="container">
                    <video
                      ref={videoRef}
                      className={"video-js vjs-default-skin w-100 " + styles.videoVoz}
                      controls
                      muted
                      preload="auto"
                      poster=""
                      data-setup='{"controls": true, "autoplay": false}'
                      onEnded={detenerGrabacionVoz}  // Detener grabación cuando el video termine
                      style={{ pointerEvents: 'none' }}
                    >
                      <source src={location.state.urlVideo} type="video/MP4" />
                    </video>
                    <p className='text-light mt-5 text-white text-center'>haz click para iniciar la grabación de voz. Puedes intentarlo las veces que sea necesario. Luego en la parte inferior, puedes reproducir para probar.</p>
                    {/* Botón grabar */}
                    <div className='d-flex justify-content-center pt-1'>
                      <button
                        className="btn btn-danger btn-lg w-100"
                        onClick={iniciarGrabacionVoz}
                        disabled={grabando}
                      >
                      {grabando ? "Grabando..." : "Grabar Voz"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            : <p>En proceso</p>
            }

            {/* Contenedor audio */}
            {(archivos)
            ?<div className='container'>
              <h2 className="d-flex justify-content-center mt-5">Audio grabado</h2>
              <p>Es es el audio que acabas de grabar. Haz click para reproducir y si te gusta, oprime en "UNIR" para sincronizar el video con el audio.</p>
              {/* Audio */}
              <div className='d-flex justify-content-center mt-5'>
                <audio ref={audioRef} controls style={{ pointerEvents: 'none' }} >
                  <source src={urlConParametro} type="audio/wav" />
                </audio>
              </div>
              {/* Botón para reproducir todo */}
              <div className='d-flex justify-content-center mb-5'>
                <button onClick={reproducirTodo} className="btn btn-primary btn-lg mt-3" >
                    Reproducir Todo
                </button>
              </div>
              {/* Botón para UNIR */}
              <h2 className="d-flex justify-content-center mt-5">Unir todo</h2>
              <p> <strong>IMPORTANTE:</strong> al hacer click en "Unir audio y video" no habrá vuelta atrás. El video quedará con el audio grabado y mostrado arriba. Por esto, verifica varias veces antes de hacer click. </p>
              <div className='d-flex justify-content-center mb-5'>
              <p></p>
                <button onClick={unirTodo} className="btn btn-warning btn-lg mt-3" >
                    Unir audio y video
                </button>
              </div>
            </div>
            :null
            }
          </div>
      </div>
    </div>
  );
}

export default AnadirVoz;