import styles from '../estilos/inicioRegistro.module.css';
import React, {useState} from "react";
import axios from "axios";
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import FooterInicioRegistro from '../partials/footerInicioRegistro';


function Registro() {

    // Declaramos navigate para redirección
    const navigate = useNavigate();


    
    // declaración objeto inicial
    const[input, setInput] = useState ({
        email: "",
        username: "",
        nombre: "",
        apellido: "",
        password: ""
    });
    
    // cambiar el valor por el que escribe el usuario
    function handleChange(event) {
        const { name, value } = event.target;

        // Filtrar solo para el campo "username"
        const filteredValue = name === "username" 
            ? value.replace(/[^A-Za-z0-9]/g, '')  // Permitir solo letras y números sin espacios
            : value;  // Para otros campos, dejar el valor sin filtrar

        // guardar el valor previo.
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: filteredValue
            };
        });
    }

    
    // se activa cuando se oprime el botón
    function handleClick(event){
        // evita el parpadeo predefinido
        event.preventDefault();

        // crear objeto para pasar a servidor
        const nUsuario = {
            email: input.email,
            username: input.username,
            nombre: input.nombre,
            apellido: input.apellido,
            password: input.password
        }

        // Creamos variables para verificar username
        var validarEspacio = /\s/; // Contiene espacios
        var validarPalabra = /\W/; // Coincide con cualquier carácter que no sea una palabra .
        // Si tiene errores el usuario
        if(validarEspacio.test(nUsuario.username) || validarPalabra.test(nUsuario.username)){ navigate("/alerta", {state: {titulo:"Error en usuario", mensaje:"Sin espacios, solo letras y números. Al menos 4 caracteres.", urlMensaje:"/registrar", nombreBoton:"Volver a registrar"} } ); }
        else{
            // si no tiene errores el usuario entonces pasamos datos a servidor o bd.
            axios.post("/registrar", nUsuario)
            .then(function (response) {
                // Si el usuario ya existe redireccionamos a alerta para crear nuevamente
                if(response.data.existe){ navigate("/alerta", {state: {titulo:"Usuario ya existe", mensaje:"El usuario ya existe, por favor intenta con otro usuario.", urlMensaje:"/registrar", nombreBoton:"Volver a registrar"} } ); }
                // Si no existe, es decir ya se creó, entonces redireccionamos a iniciar sesión
                else if(response.data.existe===false){ navigate("/alerta", {state: {titulo:"Usuario creado exitosamente", mensaje:"Te has registrado exitosamente. Haz click para iniciar sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Iniciar sesión"} } ); }
            })
            .catch(function(err){console.log(err);});
        }
    }



    return(
        <div className={styles.container}>
            <main className={styles['form-signin']}>
                <form>
                    {/* Imagen y título */}
                    <div className='d-flex justify-content-center'>
                        <img className="my-5" src="/imagenes/marca/clvcazul.png" alt="" height="200" />
                    </div>
                    <h3 className="h3 mb-3 fw-normal text-light text-center">Registrar</h3>


                    {/* Email */}
                    <div className="form-floating mb-2">
                        <input onChange={handleChange} value={input.email} name="email" type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label htmlFor="floatingInput">Email</label>
                    </div>

                    {/* nombre */}
                    <div className="form-floating mb-2">
                        <input onChange={handleChange} value={input.nombre} name="nombre" type="text" className="form-control"  placeholder="mi nombre" />
                        <label htmlFor="floatingInput">Nombre</label>
                    </div>

                    {/* Apellido */}
                    <div className="form-floating mb-2">
                        <input onChange={handleChange} value={input.apellido} name="apellido" type="text" className="form-control"  placeholder="mi apellido" />
                        <label htmlFor="floatingInput">Apellido</label>
                    </div>

                    {/* username */}
                    <div className="form-floating mb-2">
                        <input onChange={handleChange} value={input.username} required name="username" type="text" className="form-control"  placeholder="miusuario123" pattern="[A-Za-z0-9]*"  title="Sin espacios, solo letras y números. Al menos 4 caracteres." />
                        <label htmlFor="floatingInput">Usuario</label>
                    </div>

                    {/* Password  */}
                    <div className="form-floating mb-2">
                        <input onChange={handleChange} value={input.password} name="password" type="password" className="form-control" id="floatingPassword" placeholder="Password" title="Sin espacios, solo letras y números. Al menos 4 caracteres." />
                        <label htmlFor="floatingPassword">contraseña</label>
                    </div>

                    {/* Botón regsitrar */}
                    <button onClick={handleClick} className="btn btn-primary w-100 py-2" type="submit">Registrar</button>

                    
                    {/* footer */}
                    <FooterInicioRegistro />  
                </form>
            </main>
        </div>
    )
}


export default Registro; 