import '../../estilos/paginas.css';
import { useEffect } from 'react';



function Terminos() {
  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
 
    
  return(
    <div className='contenedorPaginasUsuario'>
      
      

        {/* Primer contenedor: imagen principal */}
        <div className='contenedorTerminos'>
            <img className='logoContendorPrincipal' src='/imagenes/marca/comolaviob.png' alt=''></img>
            <p className='subContenedorPrincipal'>Términos y condiciones</p>
        </div>

        {/* contenido */}
        <div className='container my-5'>
          <div class="p-5 mb-4 bg-body-tertiary rounded-3">
              <div class="container-fluid py-5">
                  <h1 class="display-5 fw-bold">Términos y condiciones</h1>
                  <p class="col-md-12">
                    VISIÓN DE CONJUNTO
                    <br />
                    Este sitio web es operado por Cómo la vio. En todo el sitio, los términos "nosotros", "nos" y "nuestro" se refieren a Cómo la vio. Cómo la vio ofrece este sitio web, incluida toda la información, las herramientas y los servicios disponibles de este sitio para usted, el usuario, condicionado a su aceptación de todos los términos, condiciones, políticas y avisos que se especifican aquí.

                    Al visitar nuestro sitio y / o comprar algo de nosotros, usted participa en nuestro "Servicio" y acuerda estar sujeto a los siguientes términos y condiciones ("Términos de servicio", "Términos"), incluidos los términos y condiciones adicionales y políticas referenciado aquí y / o disponible por hipervínculo. Estos Términos de Servicio se aplican a todos los usuarios del sitio, incluidos, entre otros, los usuarios que son navegadores, proveedores, clientes, comerciantes y / o contribuidores de contenido.

                    Lea estos Términos de servicio detenidamente antes de acceder o utilizar nuestro sitio web. Al acceder o usar cualquier parte del sitio, usted acepta estar sujeto a estos Términos de servicio. Si no acepta los términos y condiciones de este acuerdo, entonces no podrá acceder al sitio web ni utilizar ningún servicio. Si estos Términos de servicio se consideran una oferta, la aceptación está expresamente limitada a estos Términos de servicio.

                    Las nuevas funciones o herramientas que se agregan a la tienda actual también deben estar sujetas a los Términos del servicio. Puede revisar la versión más reciente de los Términos de Servicio en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquiera de estos Términos de Servicio mediante la publicación de actualizaciones y / o cambios en nuestro sitio web. Es su responsabilidad revisar esta página periódicamente para ver los cambios. Su uso continuo del acceso al sitio web después de la publicación de cualquier cambio, la aceptación de esos cambios.

                    Nuestra web  está alojada en heroku. Nos proporcionan la plataforma de comercio electrónico en línea que nos permite venderle nuestros productos y servicios.
                    <br />
                    <br />
                    SECCIÓN 1 - TÉRMINOS DE LA TIENDA EN LÍNEA
                    <br />
                    Al aceptar estos Términos de servicio, usted representa al menos la mayoría de edad en su estado de residencia, o que tiene la mayoría de edad en su estado de residencia y nos ha dado su consentimiento para Permitir que cualquiera de sus dependientes menores a usa este sitio
                    No puede utilizar nuestros productos para ningún propósito ilegal o no autorizado, ni puede, en el uso del Servicio, violar las leyes de su jurisdicción (incluidas, entre otras, las leyes de derechos de autor).
                    No debe transmitir ningún gusano o virus ni ningún código de naturaleza destructiva.
                    Una violación o violación de cualquiera de los Términos dará como resultado la terminación inmediata de sus Servicios.
                    <br />
                    <br />
                    SECCION 2 - CONDICIONES GENERALES
                    <br />
                    Nos reservamos el derecho de rechazar el servicio a cualquier persona por cualquier motivo en cualquier momento.
                    Usted comprende que su contenido (sin incluir la información de la tarjeta de crédito) puede transferirse sin cifrar e involucrar (a) transmisiones en varias redes; y (b) cambios para cumplir y adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de la tarjeta de crédito siempre se cifra durante la transferencia a través de las redes.
                    Usted acepta no reproducir, duplicar, copiar, vender, revender ni explotar ninguna parte del Servicio, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin el permiso expreso de nuestro .
                    Los encabezados utilizados en este acuerdo se incluyen solo por conveniencia y no limitarán ni afectarán estos Términos.
                    <br />
                    <br />
                    SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y PUNTUALIDAD DE LA INFORMACIÓN
                    <br />
                    No somos responsables si la información disponible en este sitio no es precisa, completa o actual. El material de este sitio se proporciona solo para información general y no se debe confiar ni utilizar como la única base para tomar decisiones sin consultar fuentes de información primarias, más precisas, más completas u más oportunas. Cualquier confianza en el material de este sitio es bajo su propio riesgo.
                    Este sitio puede contener cierta información histórica. La información histórica, necesariamente, no es actual y se proporciona únicamente como referencia. Nos reservamos el derecho de modificar el contenido de este sitio en cualquier momento, pero no tenemos la obligación de actualizar ninguna información en nuestro sitio. Usted acepta que es su responsabilidad controlar los cambios en nuestro sitio.
                    <br />
                    <br />
                    SECCIÓN 4 - MODIFICACIONES AL SERVICIO Y PRECIOS
                    <br />
                    Los precios de nuestros productos están sujetos a cambios sin previo aviso.
                    Nos reservamos el derecho en cualquier momento de modificar o descontinuar el Servicio (o cualquier parte o contenido del mismo) sin previo aviso en cualquier momento.
                    No seremos responsables ante usted por ningún tercero por cualquier modificación, cambio de precio, suspensión o interrupción del Servicio.
                    <br />
                    <br />  
                    SECCIÓN 5 - PRODUCTOS O SERVICIOS (si corresponde)
                    <br />
                    Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web. Estos productos o servicios pueden tener cantidades limitadas y están sujetos a devolución o cambio solo de acuerdo con nuestra Política de Devolución.
                    Hemos hecho todos los esfuerzos posibles para mostrar con la mayor precisión posible los colores y las imágenes de nuestros productos que aparecen en la tienda. No podemos garantizar que la pantalla del monitor de su computadora tenga ningún color.
                    Nos reservamos el derecho, pero no estamos obligados, de limitar las ventas de nuestros productos o servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho caso por caso. Nos reservamos el derecho de limitar las cantidades de cualquier producto o servicio que ofrecemos. Todas las descripciones de los productos o los precios de los productos están sujetos a cambios en cualquier momento y sin previo aviso, a exclusivo criterio de nosotros. Nos reservamos el derecho de descontinuar cualquier producto en cualquier momento. Cualquier oferta por cualquier producto o servicio realizado en este sitio no tiene validez donde esté prohibida.
                    No garantizamos que la calidad de ningún producto, servicio, información u otro material comprado u obtenido por usted cumplirá con sus expectativas, o que cualquier error en el Servicio será corregido.
                    <br />
                    <br />
                    SECCIÓN 6 - PRECISIÓN DE LA FACTURA Y LA INFORMACIÓN DE LA CUENTA
                    <br />
                    Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. Podemos, a nuestro exclusivo criterio, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, la misma tarjeta de crédito y / o pedidos que usan la misma dirección de facturación y / o envío. En el caso de que realicemos un cambio o cancelamos un pedido, podemos intentar notificarlo contactando el correo electrónico y / o la dirección de facturación / número de teléfono proporcionados en el momento en que se realizó el pedido. Nos reservamos el derecho de limitar o prohibir los pedidos que, a nuestro exclusivo criterio, parezcan haber sido realizados por distribuidores, revendedores o distribuidores.
                    Usted acepta proporcionar información de compra y cuenta actual, completa y precisa para todas las compras realizadas en nuestra tienda. Usted acepta actualizar rápidamente su cuenta y otra información, incluida su dirección de correo electrónico y los números de tarjeta de crédito y fechas de vencimiento, para que podamos completar sus transacciones y contactarlo según sea necesario.
                    Para obtener más detalles, revise nuestra Política de devoluciones.
                    <br />
                    <br />
                    SECCION 7 - HERRAMIENTAS OPCIONALES
                    <br />
                    Podemos proporcionarle acceso a herramientas de terceros sobre las cuales no supervisamos ni tenemos ningún control ni aporte.
                    Usted reconoce y acepta que brindamos acceso a dichas herramientas "tal cual" y "según disponibilidad" sin garantías, representaciones o condiciones de ningún tipo y sin ningún tipo de endoso. No tendremos ninguna responsabilidad derivada o relacionada con su uso de herramientas de terceros opcionales.
                    Cualquier uso que haga de las herramientas opcionales ofrecidas a través del sitio es bajo su propio riesgo y discreción, y debe asegurarse de estar familiarizado y aprobar los términos sobre las herramientas provistas por los proveedores de terceros relevantes.
                    También podemos, en el futuro, ofrecer nuevos servicios y / o características a través del sitio web (incluido el lanzamiento de nuevas herramientas y recursos). Tales nuevas características y / o servicios también estarán sujetos a estos Términos de Servicio.
                    <br />
                    SECCIÓN 8 - ENLACES DE TERCEROS
                    <br />
                    Ciertos contenidos, productos y servicios disponibles a través de nuestro Servicio pueden incluir materiales de terceros.
                    Los enlaces de terceros en este sitio pueden dirigirlo a sitios web de terceros que no están afiliados a nosotros. No somos responsables de examinar o evaluar el contenido o la precisión y no garantizamos ni asumiremos ninguna responsabilidad por los materiales o sitios web de terceros, ni por ningún otro material, producto o servicio de terceros.
                    No somos responsables de ningún daño o perjuicio relacionado con la compra o uso de bienes, servicios, recursos, contenido o cualquier otra transacción realizada en conexión con sitios web de terceros. Revise cuidadosamente las políticas y prácticas de terceros y asegúrese de comprenderlas antes de participar en cualquier transacción. Las quejas, reclamos, inquietudes o preguntas sobre productos de terceros deben dirigirse a un tercero.
                    <br />
                    <br />
                    SECCIÓN 9 - COMENTARIOS DE LOS USUARIOS, RETROALIMENTACIÓN Y OTRAS COMUNICACIONES
                    <br />
                    Si, a petición nuestra, envía ciertos envíos específicos (por ejemplo, participaciones en concursos) o sin una solicitud nuestra, envía ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de otro modo. (colectivamente, 'comentarios'), usted acepta que, en cualquier momento, sin restricción, editemos, copiemos, publiquemos, distribuyamos, traduzcamos y utilicemos en cualquier medio cualquier comentario que nos envíe. Estamos y no tenemos ninguna obligación (1) de mantener ningún comentario en confianza; (2) pagar una compensación por cualquier comentario; o (3) para responder a cualquier comentario.
                    Podemos, pero no tenemos la obligación de, monitorear, editar o eliminar contenido que a nuestro exclusivo criterio determinamos que son ilegales, ofensivos, amenazantes, calumniosos, difamatorios, pornográficos, obscenos o de otra manera objetables o que violan la propiedad intelectual de cualquiera de las partes o estos Términos de Servicio. .
                    Usted acepta que sus comentarios no violarán ningún derecho de terceros, incluidos los derechos de autor, marca registrada, privacidad, personalidad u otro derecho personal o de propiedad. Además, acepta que sus comentarios no contengan material difamatorio o ilegítimo, abusivo u obsceno, ni contengan ningún virus informático u otro malware que pueda afectar el funcionamiento del Servicio o cualquier sitio web relacionado. No puede utilizar una dirección de correo electrónico falsa, pretender ser otra persona que no sea usted mismo o, de otro modo, engañarnos a nosotros o a terceros sobre el origen de los comentarios. Usted es el único responsable de los comentarios que realice y su exactitud. No asumimos ninguna responsabilidad y no asumimos ninguna responsabilidad por los comentarios publicados por usted o un tercero.
                    <br />
                    <br />
                    SECCION 10 - INFORMACION PERSONAL
                    <br />
                    Su envío de información personal a través de la tienda se rige por nuestra Política de privacidad. Para ver nuestra política de privacidad.
                    <br />
                    <br />
                    SECCIÓN 11 - ERRORES, INEXACTITUDES Y OMISIONES
                    <br />
                    Ocasionalmente, puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, imprecisiones u omisiones que pueden estar relacionadas con descripciones de productos, precios, promociones, ofertas, cargos por envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, y cambiar o actualizar información o cancelar pedidos si cualquier información en el Servicio o en cualquier sitio web relacionado es incorrecta en cualquier momento sin previo aviso (incluso después de haber enviado su pedido) .
                    No asumimos ninguna obligación de actualizar, modificar o aclarar la información en el Servicio o en cualquier sitio web relacionado, incluyendo, entre otros, la información de precios, excepto que lo exija la ley. Ninguna actualización especificada o fecha de actualización aplicada en el Servicio o en cualquier sitio web relacionado, debe tomarse para indicar que toda la información en el Servicio o en cualquier sitio web relacionado ha sido modificada o actualizada.
                    <br />
                    <br />
                    SECCIÓN 12 - USOS PROHIBIDOS
                    <br />
                    Además de otras prohibiciones según lo establecido en los Términos del Servicio, tiene prohibido el uso del sitio o su contenido: (a) para cualquier propósito ilegal; (b) para solicitar que otros realicen o participen en actos ilegales; (c) violar cualquier regulación, regla, ley u ordenanza local internacional, federal, provincial o estatal; (d) infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de terceros; (e) hostigar, abusar, insultar, dañar, difamar, difamar, menospreciar, intimidar o discriminar por motivos de género, orientación sexual, religión, etnia, raza, edad, origen nacional o discapacidad; (f) para enviar información falsa o engañosa; (g) cargar o transmitir virus o cualquier otro tipo de código malicioso que pueda o pueda ser utilizado de cualquier forma que afecte la funcionalidad u operación del Servicio o de cualquier sitio web relacionado, otros sitios web o Internet; (h) para recopilar o rastrear la información personal de otros; (i) spam, phishing, pharm, pretexto, araña, rastreo o raspado; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir o eludir las características de seguridad del Servicio o cualquier sitio web relacionado, otros sitios web o Internet. Nos reservamos el derecho de terminar su uso del Servicio o cualquier sitio web relacionado por violar cualquiera de los usos prohibidos.
                    <br />
                    <br />
                    SECCIÓN 13 - DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD
                    <br />
                    No garantizamos, representamos ni garantizamos que su uso de nuestro servicio será ininterrumpido, oportuno, seguro o libre de errores.
                    No garantizamos que los resultados que puedan obtenerse del uso del servicio sean precisos o confiables.
                    Usted acepta que de vez en cuando podemos eliminar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento, sin notificárselo.
                    Usted acepta expresamente que su uso o la imposibilidad de utilizar el servicio es bajo su propio riesgo. El servicio y todos los productos y servicios entregados a través del servicio son (salvo que así lo especifiquemos nosotros) proporcionados "tal como están" y "disponibles" para su uso, sin ninguna representación, garantía o condición de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías implícitas o condiciones de comerciabilidad, calidad comercial, idoneidad para un propósito particular, durabilidad, título y no infracción.
                    En ningún caso, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, pasantes, proveedores, proveedores de servicios o licenciantes serán responsables por cualquier lesión, pérdida, reclamo o cualquier tipo de daño directo, indirecto, incidental o punitivo. daños especiales o consecuentes de cualquier tipo, incluidos, entre otros, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo o daños similares, ya sea por contrato, responsabilidad extracontractual (incluida negligencia), responsabilidad objetiva o de otro tipo, que surja de su uso de cualquiera de los servicios o productos adquiridos mediante el servicio, o de cualquier otro reclamo relacionado de alguna manera con su uso del servicio o cualquier producto, incluidos, entre otros, cualquier error u omisión en cualquier contenido , o cualquier pérdida o daño de cualquier tipo incurrido como resultado del uso del servicio o cualquier contenido (o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se informa de su posibilidad. Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuentes o incidentales, en dichos estados o jurisdicciones, nuestra responsabilidad se limitará a la extensión máxima permitida por la ley.
                    <br />
                    <br />
                    SECCIÓN 14 - INDEMNIZACIÓN
                    <br />
                    Usted acepta indemnizar, defender y eximir de responsabilidad a Cómo la vio y a nuestras empresas matrices, subsidiarias, afiliadas, socios, funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, de cualquier reclamación o demanda. , incluidos los honorarios razonables de los abogados, realizados por terceros debido a su incumplimiento de estos Términos de servicio o los documentos que incorporan por referencia, o su violación de cualquier ley o los derechos de un tercero.
                    <br />
                    <br />
                    SECCION 15 - SEVERABILIDAD
                    <br />
                    En el caso de que se determine que alguna disposición de estos Términos de servicio es ilegal, nula o inaplicable, dicha disposición será, no obstante, exigible en la mayor medida permitida por la ley aplicable, y la parte no ejecutable se considerará separada de estos Términos de Servicio, tal determinación no afectará la validez y aplicabilidad de cualquier otra disposición restante.
                    <br />
                    <br />
                    SECCION 16 - TERMINACION
                    <br />
                    Las obligaciones y responsabilidades de las partes incurridas antes de la fecha de finalización continuarán vigentes después de la terminación de este contrato para todos los propósitos.
                    Estos Términos de Servicio entran en vigencia a menos que usted o nosotros los rescindamos. Puede rescindir estos Términos de Servicio en cualquier momento notificándonos que ya no desea utilizar nuestros Servicios o que deje de utilizar nuestro sitio.
                    Si a nuestro exclusivo criterio falla, o sospechamos que ha fallado, para cumplir con cualquier término o disposición de estos Términos de servicio, también podemos rescindir este acuerdo en cualquier momento sin previo aviso y usted seguirá siendo responsable de todos los importes adeudados. hasta e incluyendo la fecha de terminación; y / o en consecuencia puede denegarle el acceso a nuestros Servicios (o parte de ellos).
                    <br />
                    <br />
                    SECCIÓN 17 - ACUERDO COMPLETO
                    <br />
                    El hecho de que no ejerzamos o hagamos valer ningún derecho o disposición de estos Términos de servicio no constituirá una renuncia a dicho derecho o disposición.
                    Estos Términos de Servicio y cualquier política o regla de operación publicada por nosotros en este sitio o con respecto a El Servicio constituye el acuerdo completo y entendimiento entre usted y nosotros y rigen el uso del Servicio, reemplazando cualquier acuerdo, comunicación o propuesta anterior o contemporánea. , ya sea oral o escrito, entre usted y nosotros (incluidas, entre otras, versiones anteriores de los Términos del Servicio).
                    Cualquier ambigüedad en la interpretación de estos Términos de Servicio no se interpretará en contra de la parte redactora.
                    <br />
                    <br />
                    SECCIÓN 18 - LEY APLICABLE
                    <br />
                    Estos Términos de Servicio y cualquier otro acuerdo por el cual le proporcionemos Servicios se regirán e interpretarán de conformidad con las leyes.
                    <br />
                    <br />
                    SECCIÓN 19 - CAMBIOS EN LOS TÉRMINOS DE SERVICIO
                    <br />
                    Puede revisar la versión más reciente de los Términos de Servicio en cualquier momento en esta página.
                    Nos reservamos el derecho, a nuestra entera discreción, de actualizar, cambiar o reemplazar cualquier parte de estos Términos de Servicio publicando actualizaciones y cambios en nuestro sitio web. Es su responsabilidad consultar nuestro sitio web periódicamente para ver los cambios. Su uso o acceso continuo a nuestro sitio web o al Servicio después de la publicación de cualquier cambio en estos Términos de Servicio constituye la aceptación de dichos cambios.
                    <br />
                    <br />
                    SECCIÓN 20 - INFORMACIÓN DE CONTACTO
                    <br />
                    Las preguntas sobre los Términos del Servicio deben enviarse a soporte@comolavio.com.
                  </p>
              </div>
          </div>
        </div>
    </div>
  )
}



export default Terminos;